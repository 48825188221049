import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/user/location.model';
import { environment } from '../../environments/environment';

@Injectable()
export class MeetService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService
  ) { }

  public loadFilters() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let locationNumber = loc.locationNumber;
        const url = `/api/${locationNumber}/default-filters`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res),
      catchError(this.ehs.handleError)
    );
  }

  public loadSavedGroups() {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/custom-filters`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public loadSelectedSavedGroupCustomers(groupId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/${groupId}/customFilters/customers`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public updateSavedGroup(updatedGroup) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/${updatedGroup.id}/customFilters/customers`;
        return this.http.put(environment.spotlightApiUrl + url, updatedGroup);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public deleteSavedGroup(groupId) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/${groupId}/custom-filters`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }

  public createSavedGroup(groupPayload) {
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        let url = `/api/location/${loc.locationNumber}/createCustomFilter`;
        return this.http.post(environment.spotlightApiUrl + url, groupPayload);
      }),
      map((res: any) => res.responseObject),
      catchError(this.ehs.handleError)
    );
  }
}
