import { environment } from 'spotlight-ui/environments/environment';

export default {
  oidc: {
    clientId: environment.oktaClientId,
    issuer: environment.oktaAuthIssuer,
    redirectUri: environment.uiRedirectUrl,
    endSessionRedirectUri: environment.endSessionRedirectUri,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 60
    }
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};

