import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { any } from 'ramda';
import * as fromRoot from '../reducers';
import * as engage from '../actions/engage';
import * as calendar from '../actions/calendar';
import * as user from '../actions/user';
import * as meet from '../actions/meet';
import * as customer from '../actions/customer';
import { EngageService } from '../engage/engage.service';
import { DiscoverService } from '../discover/discover.service';
import { EngageConstants } from '../engage/engage.constants';
import { CalendarService } from '../calendar/calendar.service';
import { FindCustomerResponse } from '../models/customer/find-customer-response.model';
import { AppConstants } from '../app.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SendConfirmationDialogComponent }
  from '../shared/send-confirmation-dialog/send-confirmation-dialog.component';
import { SharedConstants } from '../shared/shared.constants';

@Injectable()
export class EngageEffects {
  // Fetches the reward templates for the engage page
  @Effect()
  public fetchRewardTemplates$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      engage.ActionTypes.SET_SELECTED_TAB_INDEX,
      engage.ActionTypes.CHOOSE_NEW_REWARD_FOR_FAILED
    ),
    concatLatestFrom(() => this.store.select(fromRoot.getEngageRewards)),
    filter(([action, rewards]) => rewards.templates.length < 1
      && this.isSelectedTabIndex(EngageConstants.rewardTabIndex)),
    switchMap(() => this.es.getTreats().pipe(
      map((res) => new engage.FetchRewardsSuccess(res)),
      catchError((error) => of(new engage.FetchRewardsFailure(error)))
    ))
  );

  // Fetches the event templates for the engage page
  @Effect()
  public fetchEventTemplates$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      engage.ActionTypes.SET_SELECTED_TAB_INDEX,
      engage.ActionTypes.FETCH_EVENTS,
      calendar.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS
    ),
    concatLatestFrom(() => this.store.select(fromRoot.getEngageEvents)),
    filter(([action, events]) => events.length < 1
      && this.isSelectedTabIndex(EngageConstants.eventTabIndex)),
    switchMap(() => this.es.getEventTemplates().pipe(
      map((res) => new engage.FetchEventTemplatesSuccess(res)),
      catchError((error) => of(new engage.FetchEventTemplatesFailure(error)))
    ))
  );

  // Fetches the email templates for the engage page
  @Effect()
  public fetchEmailTemplates$: Observable<Action> = this.actions$.pipe(
    ofType(
      user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS,
      engage.ActionTypes.SET_SELECTED_TAB_INDEX,
      engage.ActionTypes.FETCH_EMAILS,
      engage.ActionTypes.SAVE_EMAIL_DRAFT_SUCCESS,
      engage.ActionTypes.DELETE_EMAIL_DRAFT_SUCCESS
    ),
    concatLatestFrom(() => this.store.select(fromRoot.getEngageEmails)),
    filter(([action, emails]) => emails.length < 1
      && this.isSelectedTabIndex(EngageConstants.emailTabIndex)),
    switchMap(() => this.es.getEmailTemplates().pipe(
      map((res) => new engage.FetchEmailTemplatesSuccess(res)),
      catchError((error) => of(new engage.FetchEmailTemplatesFailure(error)))
    ))
  );

  // Fetches the reward preview html and metadata
  @Effect()
  public fetchRewardTemplateDetails$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.SELECT_ENGAGE_REWARD,
      calendar.ActionTypes.FETCH_REWARD_DETAILS_SUCCESS,
      calendar.ActionTypes.FETCH_AUTOMATED_REWARD_DETAILS_SUCCESS,
      engage.ActionTypes.CHOOSE_NEW_REWARD_FOR_FAILED
    ),
    switchMap(() => this.es.getRewardTemplateDetails().pipe(
      map((res) => new engage.RewardHtmlAndMetadataFound(res)),
      catchError((error) => of(new engage.FetchTemplateDetailsFailure(error)))
    ))
  );

  // Fetches the event preview html
  @Effect()
  public fetchEventTemplateDetails$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.SELECT_ENGAGE_EVENT,
      calendar.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap(() => this.es.getEventTemplateDetails().pipe(
      map((res) => new engage.FetchEventTemplateHtmlSuccess(res)),
      catchError((error) => of(new engage.FetchEventTemplateHtmlFailure(error)))
    ))
  );

  // Fetches the email preview html
  @Effect()
  public fetchEmailTemplateDetails$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.SELECT_ENGAGE_EMAIL,
      calendar.ActionTypes.FETCH_EMAIL_DETAILS_SUCCESS
    ),
    map((action: any) => action.payload),
    switchMap((payload) => {
      // When loading calendar previews the id is set to be the batch id instead of the needed
      // template id so using templateTitle is necessary instead
      const id = payload.templateTitle ? payload.templateTitle : payload.id;
      return this.es.getTemplateDetails(id).pipe(
        map((res) => new engage.FetchTemplateDetailsSuccess(res)),
        catchError((error) => of(new engage.FetchTemplateDetailsFailure(error)))
      );
    })
  );

  @Effect()
  public fetchDraftDetailsAndDisplayFilters$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SELECT_EMAIL_DRAFT),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.getAllFilters)),
    switchMap(([action, allFilters]) => this.es.getDraftDetails(action, allFilters).pipe(
      map((res) => new engage.FetchEmailDraftSuccess(res)),
      catchError((error) => of(new engage.FetchEmailDraftError(error)))
    ))
  );

  @Effect()
  public getCalendarEvents$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.GO_TO_OVERLAPPING_ENGAGEMENT
    ),
    switchMap(() => this.store.select(fromRoot.getEngageCalendarPayload).pipe(take(1))),
    concatLatestFrom(() => this.store.select(fromRoot.getOverlappingEngagements)),
    filter(([engageDateRange, overlappingEngagements]) => overlappingEngagements.engagements.length === 0),
    switchMap(([engageDateRange, engagements]) => this.cs.getCalendarEvents(engageDateRange).pipe(
      map((res) => new engage.CalendarEventsFound(res)),
      catchError((error) => of(new engage.CalendarEventsError(error)))
    ))
  );

  @Effect()
  public sendReward$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SEND_REWARD),
    switchMap(() => this.store.select(fromRoot.getSendOfferRequest).pipe(take(1))),
    switchMap((reward) => this.es.sendReward(reward).pipe(
      map((res) => new engage.SendRewardSuccess(res)),
      catchError((error) => of(new engage.SendRewardFailure(error)))
    ))
  );

  @Effect()
  public sendEvent$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SEND_EVENT),
    switchMap(() => this.store.select(fromRoot.getEventPayload).pipe(take(1))),
    switchMap((event) => this.es.sendEvent(event).pipe(
      map((res) => new engage.SendEventSuccess(res)),
      catchError((error) => of(new engage.SendEventFailure(error)))
    ))
  );

  @Effect()
  public sendEmail$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SEND_EMAIL),
    switchMap(() => this.store.select(fromRoot.getEmailPayload).pipe(take(1))),
    switchMap((email) => this.es.sendEmail(email).pipe(
      map((res) => new engage.SendEmailSuccess(res)),
      catchError((error) => of(new engage.SendEmailFailure(error)))
    ))
  );

  @Effect()
  public sendDraft$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.SEND_DRAFT),
    switchMap(() => this.store.select(fromRoot.getDraftPayload(false)).pipe(take(1))),
    switchMap((draft) => this.es.sendEmail(draft).pipe(
      map((engagementId) => {
        const response = { ...draft, engagementId };
        return new engage.SendDraftSuccess(response);
      }),
      catchError((error) => of(new engage.SendEmailFailure(error)))
    ))
  );

  @Effect()
  public resendReward$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.RETRY_FAILED_REWARD_SEND),
    map((action: any) => action.payload),
    switchMap((ids) => this.store.select(fromRoot.getCalendarEventResendTreat(ids)).pipe(take(1))),
    switchMap((treat) => this.es.resendReward(treat).pipe(
      switchMap(() => this.store.select(fromRoot.getEngageType).pipe(take(1))),
      map((type) => new engage.SendRewardSuccess({ type })),
      catchError((error) => of(new engage.SendRewardFailure(error)))
    ))
  );

  @Effect({ dispatch: false })
  public OpenEngageDialog: Observable<any> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.SEND_EMAIL,
      engage.ActionTypes.SEND_DRAFT,
      engage.ActionTypes.SEND_EVENT,
      engage.ActionTypes.SEND_REWARD,
      engage.ActionTypes.EDIT_EVENT,
      engage.ActionTypes.EDIT_EMAIL
    ),
    map(() => {
      this.openEngageSendConfirmationDialog();
    })
  );

  @Effect({ dispatch: false })
  public ViewOnCalendar: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.VIEW_ON_CALENDAR),
    tap((data) => {
      this.dialog.closeAll();
      const queryParams = data.payload;
      this.router.navigate(['/engage/engage-details'], { queryParams });
    })
  );

  @Effect({ dispatch: false })
  public GoToEngagePage: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.GO_TO_ENGAGE_PAGE),
    tap(() => {
      this.dialog.closeAll();
      this.router.navigate(['/engage/template-selection']);
    })
  );

  @Effect()
  public cancelReward$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_REWARD),
    map((action: any) => action.payload),
    switchMap((RewardToDelete) => this.es.cancelReward(RewardToDelete).pipe(
      map((cancelReward) => new engage.CancelRewardSuccess(cancelReward)),
      catchError((error) => of(new engage.CancelRewardError(error))))
    )
  );

  @Effect()
  public cancelThirdPartyPromotion$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION),
    map((action: any) => action.payload),
    switchMap((payload) => this.ds.stopThirdPartyPromotionById(payload).pipe(
      map((cancelEvent) => new engage.CancelThirdPartyPromotionSuccess(cancelEvent)),
      catchError((error) => of(new engage.CancelThirdPartyPromotionError(error))))
    )
  );

  @Effect()
  public cancelEvent$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EVENT),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(([action, EventToDelete]) => this.es.cancelEvent(EventToDelete).pipe(
      map((cancelEvent) => new engage.CancelEventSuccess(cancelEvent)),
      catchError((error) => of(new engage.CancelEventError(error))))
    )
  );

  @Effect()
  public cancelEmail$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EMAIL),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(([action, EmailToDelete]) => this.es.cancelEmail(EmailToDelete).pipe(
      map((cancelEmail) => new engage.CancelEmailSuccess(cancelEmail)),
      catchError((error) => of(new engage.CancelEmailError(error))))
    )
  );

  @Effect()
  public cancelFreeInCartFromCalendar$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(([action, promoToDelete]) => this.ds.stopFreeItemInCartById(
      {
        fieldId: promoToDelete.id
      }).pipe(
      map((cancelEvent) => new engage.CancelFreeItemInCartFromCalendarSuccess(cancelEvent)),
      catchError((error) => of(new engage.CancelFreeItemInCartFromCalendarError(error))))
    )
  );

  @Effect()
  public cancelBonusPointPromotionFromCalendar$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(([EventToDelete]) => this.ds.stopBonusPointPromotionById(EventToDelete).pipe(
      map((cancelEvent) => new engage.CancelBonusPointPromotionFromCalendarSuccess(cancelEvent)),
      catchError((error) => of(new engage.CancelBonusPointPromotionFromCalendarError(error))))
    )
  );

  @Effect()
  public cancelCommunityCareEventFromCalendar$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(([action, EventToDelete]) => this.ds.stopCommunityCareEventById(EventToDelete).pipe(
      map((cancelEvent) => new engage.CancelCommunityCareEventFromCalendarSuccess(cancelEvent)),
      catchError((error) => of(new engage.CancelCommunityCareEventFromCalendarError(error))))
    )
  );

  @Effect()
  public editEvent$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.EDIT_EVENT),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(() => this.store.select(fromRoot.getEventEditPayload).pipe(take(1))),
    switchMap((event) => this.es.editEvent(event).pipe(
      map((event) => new engage.EditEventSuccess(event)),
      catchError((error) => of(new engage.EditEventError(error))))
    )
  );

  @Effect()
  public editEmail$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.EDIT_EMAIL),
    concatLatestFrom(() => this.store.select(fromRoot.getSelectedEngagement)),
    switchMap(() => this.store.select(fromRoot.getEmailEditPayload).pipe(take(1))),
    switchMap((email) => this.es.editEmail(email).pipe(
      map((res) => new engage.EditEmailSuccess(res)),
      catchError((error) => of(new engage.EditEmailError(error))))
    )
  );

  @Effect()
  public getFilterCustomersOnEdit$: Observable<any> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.GO_TO_EVENT_EDIT,
      engage.ActionTypes.GO_TO_EMAIL_EDIT
    ),
    map((action: any) => action.payload),
    filter((engagement) => {
      return !engagement.showCustomers && (engagement.selectedFilters.length > 0 || engagement.selectedSavedGroups.length > 0);
    }),
    switchMap((engagement) => this.es.loadFilters().pipe(
      map((res) => new engage.GetSelectedFilterDetailsSuccess(res)),
      catchError((error) => of(new engage.GetSelectedFilterDetailsError(error))))
    )
  );

  @Effect({ dispatch: false })
  public goToEventEdit$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.GO_TO_EVENT_EDIT),
    tap((id) => {
      const queryParams = { type: EngageConstants.event, mode: EngageConstants.editMode, id };
      this.router.navigate(['/engage/engage-details'], { queryParams }
      );
    })
  );

  @Effect({ dispatch: false })
  public goToEmailEdit$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.GO_TO_EMAIL_EDIT),
    tap((id) => {
      const queryParams = { type: EngageConstants.email, mode: EngageConstants.editMode, id };
      this.router.navigate(['/engage/engage-details'], { queryParams }
      );
    })
  );

  @Effect()
  public saveDraft$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SAVE_EMAIL_DRAFT),
    switchMap(() => this.store.select(fromRoot.getDraftPayload(true)).pipe(take(1))),
    switchMap((draft) => this.es.saveDraft(draft).pipe(
      map(() => new engage.SaveEmailDraftSuccess(draft.campaignName)),
      catchError((error) => of(new engage.SaveEmailDraftError(error))))
    )
  );

  @Effect({ dispatch: false })
  public saveEmailDraftSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SAVE_EMAIL_DRAFT_SUCCESS),
    map((action: any) => action.payload),
    tap((draftName) => {
      this.router.navigate(
        ['/engage/template-selection'],
        { queryParams: { engagementIndex: 2 } }
      );
      this.snackBar.open(
        `${draftName} draft email saved successfully`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public saveEmailDraftError$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SAVE_EMAIL_DRAFT_ERROR),
    tap(() => {
      this.router.navigate(
        ['/engage/template-selection'],
        { queryParams: { engagementIndex: 2 } }
      );
      this.snackBar.open(
        `Failed to save draft, please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect()
  public deleteDraft$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.DELETE_EMAIL_DRAFT),
    map((action: any) => action.payload),
    switchMap((draftId) => this.es.deleteDraft(draftId).pipe(
      map((res) => new engage.DeleteEmailDraftSuccess(draftId)),
      catchError((error) => of(new engage.DeleteEmailDraftError(error)))
    ))
  );

  @Effect({ dispatch: false })
  public deleteEmailDraftSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.DELETE_EMAIL_DRAFT_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Draft has been successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect({ dispatch: false })
  public deleteEmailDraftError$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.DELETE_EMAIL_DRAFT_ERROR),
    tap(() => {
      this.snackBar.open(
        `Failed to delete draft, please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );
    })
  );

  @Effect()
  public deleteDraftAfterSend$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SEND_DRAFT_SUCCESS),
    map((action: any) => action.payload),
    switchMap((draft: any) => this.es.deleteDraft(draft.draftId).pipe(
      map((res) => new engage.DeleteDraftAfterSendSuccess(draft.draftId)),
      catchError((error) => of(new engage.DeleteEmailDraftError(error)))
    )),
  );

  @Effect()
  public getSavedFilters$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.GET_SAVED_FILTERS),
    map((action: any) => action.payload),
    switchMap(() => this.es.getSavedFilters().pipe(
      map((savedFilters) => new engage.GetSavedFiltersSuccess(savedFilters)),
      catchError((error) => of(new engage.GetSavedFiltersFailure(error)))
    ))
  );

  @Effect()
  public getSavedFilterCustomers$: Observable<any> = this.actions$.pipe(
    ofType(engage.ActionTypes.GET_SAVED_FILTER_CUSTOMERS),
    map((action: any) => action.payload),
    filter((filter) => !filter.customerProfiles),
    switchMap((filter) => this.es.getSavedFilterCustomers(filter.id).pipe(
      map((filterDetails) => new engage.GetSavedFilterCustomersSuccess(filterDetails)),
      catchError((error) => of(new engage.GetSavedFilterCustomersFailure({ error, filter })))
    ))
  );

  @Effect()
  public findCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(
      meet.ActionTypes.ADD_SELECTED_FILTER,
      meet.ActionTypes.REMOVE_SELECTED_FILTER,
      meet.ActionTypes.CLEAR_FILTERS,
      meet.ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE,
      meet.ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE,
      meet.ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE,
      meet.ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER,
      meet.ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER,
      meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER,
      meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER,
      meet.ActionTypes.ADD_SAVED_GROUP,
      meet.ActionTypes.REMOVE_SAVED_GROUP,
      meet.ActionTypes.LOAD_FILTERS_SUCCESS,
      meet.ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE,
      meet.ActionTypes.ADD_CUSTOM_DATE_FILTER,
      meet.ActionTypes.UPDATE_SLIDER_VALUE
    ),
    map((action: any) => action.payload),
    filter((payload) => payload.page === AppConstants.engagePage),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.engagePage)).pipe(take(1))),
    switchMap((state) => this.es.getFilterDisplayCustomers(state).pipe(
      map((res: FindCustomerResponse) => new engage.FindFilterDisplayCustomersSuccess(res)),
      catchError((error) => of(new customer.CustomerError(error)))
    ))
  );

  @Effect()
  public findCustomersWithAlreadyLoadedFilters$: Observable<Action> = this.actions$.pipe(
    ofType(meet.ActionTypes.LOAD_FILTERS),
    map((action: any) => action.payload),
    concatLatestFrom(() => this.store.select(fromRoot.areFiltersLoaded)),
    filter(([payload, areFiltersLoaded]) => areFiltersLoaded && payload.page === AppConstants.engagePage),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.engagePage)).pipe(take(1))),
    switchMap((state) => this.es.getFilterDisplayCustomers(state).pipe(
      map((res: FindCustomerResponse) => new engage.FindFilterDisplayCustomersSuccess(res)),
      catchError((error) => of(new engage.FindFilterDisplayCustomersError(error)))
    ))
  );

  @Effect()
  public engageFilterCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.CHANGE_FILTER_PAGE,
      engage.ActionTypes.CHANGE_FILTER_PAGE_SIZE,
      engage.ActionTypes.CHANGE_FILTER_SORT,
      engage.ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_SUCCESS,
      engage.ActionTypes.GET_SELECTED_FILTER_DETAILS_SUCCESS
    ),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.engagePage)).pipe(take(1))),
    switchMap((state) => this.es.getFilterDisplayCustomers(state).pipe(
      map((res: FindCustomerResponse) => new engage.FindFilterDisplayCustomersSuccess(res)),
      catchError((error) => of(new engage.FindFilterDisplayCustomersError(error)))
    ))
  );

  @Effect()
  public updateFilterDates$: Observable<Action> = this.actions$.pipe(
    ofType(meet.ActionTypes.UPDATE_FILTER_DATES),
    map((action: any) => action.payload),
    filter((payload) => payload.page === AppConstants.engagePage),
    switchMap((payload) => this.store.select(fromRoot.getIfFiltersDateIsNull(payload.page)).pipe(take(1))),
    filter((dateIsNull) => !dateIsNull),
    switchMap((payload) => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.engagePage)).pipe(take(1))),
    switchMap(() => this.store.select(fromRoot.getCustomerAPIRequestByPage(AppConstants.engagePage)).pipe(take(1))),
    switchMap((state) => this.es.getFilterDisplayCustomers(state).pipe(
      map((res: FindCustomerResponse) => new engage.FindFilterDisplayCustomersSuccess(res)),
      catchError((error) => of(new engage.FindFilterDisplayCustomersError(error)))
    ))
  );

  @Effect()
  public getIndividualCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SET_SELECTED_DISPLAY_FILTERS),
    concatLatestFrom(() => this.store.select(fromRoot.shouldGetAllCustomers)),
    filter(([action, shouldGetAllCustomers]) => shouldGetAllCustomers),
    switchMap(() => this.store.select(fromRoot.getEngageAllSelectedCustomerIds).pipe(take(1))),
    switchMap((ids) => this.es.getCustomersByIds(ids).pipe(
      map((res) => new engage.GetCustomersByIdSuccess(res)),
      catchError((error) => of(new engage.GetCustomersByIdError(error)))
    ))
  );

  @Effect()
  public getExcludedCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.SET_SELECTED_DISPLAY_FILTERS),
    concatLatestFrom(() => this.store.select(fromRoot.getEngageExcludedCustomers)),
    filter(([action, excludedCustomers]) => any((cust) => cust.requiresCustomerDetails)(excludedCustomers)),
    switchMap(() => this.store.select(fromRoot.getEngageExcludedCustomersIds).pipe(take(1))),
    switchMap((excludedIds) => this.es.getCustomersByIds(excludedIds).pipe(
      map((res) => new engage.GetExcludedCustomersByIdSuccess(res)),
      catchError((error) => of(new engage.GetExcludedCustomersByIdError(error)))
    ))
  );

  @Effect()
  public resetCacheAfterEngageSend$: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.SEND_REWARD_SUCCESS,
      engage.ActionTypes.CANCEL_REWARD_SUCCESS,
      engage.ActionTypes.SEND_EVENT_SUCCESS,
      engage.ActionTypes.EDIT_EVENT_SUCCESS,
      engage.ActionTypes.CANCEL_EVENT_SUCCESS,
      engage.ActionTypes.SEND_EMAIL_SUCCESS,
      engage.ActionTypes.EDIT_EMAIL_SUCCESS,
      engage.ActionTypes.CANCEL_EMAIL_SUCCESS
    ),
    switchMap(() => this.es.clearCalendarEventsCache().pipe(
      map(() => new engage.ClearCacheSuccess()),
      catchError((error) => of(new engage.ClearCacheError(error)))
    ))
  );

  @Effect({ dispatch: false })
  public cancelRewardSuccess: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_REWARD_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Reward was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelRewardFailure: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_REWARD_ERROR),
    tap(() => {
      this.snackBar.open(
        `Reward failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelEventSuccess: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EVENT_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Event was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelEventFailure: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EVENT_ERROR),
    tap(() => {
      this.snackBar.open(
        `Event failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelEmailSuccess: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EMAIL_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Email was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelEmailFailure: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_EMAIL_ERROR),
    tap(() => {
      this.snackBar.open(
        `Email failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelCommunityCareEventFromCalendarSuccess: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_SUCCESS),
    tap(() => {
      this.snackBar.open(
        `Event was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelCommunityCareEventFromCalendarFailure: Observable<Action> = this.actions$.pipe(
    ofType(engage.ActionTypes.CANCEL_COMMUNITY_CARE_EVENT_FROM_CALENDAR_ERROR),
    tap(() => {
      this.snackBar.open(
        `Event failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelPromotionSuccess: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_SUCCESS,
      engage.ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_SUCCESS,
      engage.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_SUCCESS
    ),
    map((action: any) => action.payload.page),
    tap(() => {
      this.snackBar.open(
        `Promotion was successfully deleted.`,
        'Dismiss', {
          duration: 5000
        }
      );
      return this.router.navigate(['/calendar']);
    })
  );

  @Effect({ dispatch: false })
  public cancelPromotionFailure: Observable<Action> = this.actions$.pipe(
    ofType(
      engage.ActionTypes.CANCEL_BONUS_POINT_PROMOTION_FROM_CALENDAR_ERROR,
      engage.ActionTypes.CANCEL_FREE_ITEM_IN_CART_FROM_CALENDAR_ERROR,
      engage.ActionTypes.CANCEL_THIRD_PARTY_PROMOTION_ERROR
    ),
    map((action: any) => action.payload),
    tap((page) => {
      this.snackBar.open(
        `Promotion failed to delete. Please try again later.`,
        'Dismiss', {
          duration: 5000
        }
      );

      return this.router.navigate(['/calendar']);
    })
  );

  constructor(
    private actions$: Actions,
    private cs: CalendarService,
    private es: EngageService,
    private ds: DiscoverService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
  ) { }

  private isSelectedTabIndex(selectedTabIndex) {
    let isSelectedTabIndex = false;
    this.store.select(fromRoot.getSelectedTabIndex).pipe(take(1)).subscribe((tabIndex) => {
      if (selectedTabIndex === tabIndex
        && this.router.url.toString().indexOf('/engage') >= 0) {
        isSelectedTabIndex = true;
      }
    });
    return isSelectedTabIndex;
  }

  private openEngageSendConfirmationDialog() {
    let config: MatDialogConfig = {
      autoFocus: false,
      disableClose: true,
      data: { page: SharedConstants.engagePage }
    }
    this.dialog.open(SendConfirmationDialogComponent, config);
  }
}
