export class CareConstants {
  public static get pageSizeOptions(): number[] {
    return [10, 20, 30];
  }

  public static get defaultPageSize(): number {
    return 10;
  }

  public static get defaultSmallPageSize(): number {
    return 5;
  }

  public static get defaultSortOrder(): string {
    return 'asc';
  }

  public static get defaultSortBy(): string {
    return 'priority';
  }

  public static get spotCustomer(): string {
    return 'spotCustomer';
  }

  public static careColumns(membershipColumnEnabled: boolean): any[] {
    let columns: any = [{
      prop: 'fullName',
      title: 'Name',
      mobile: 'title',
      hide: true
    }, {
      title: 'Sync Report',
      type: 'syncReport',
      text: 'View',
      id: this.syncReport,
      size: 9,
      sort: false,
      rowConcatProp: 'incidentId',
    }, {
      title: 'Member Profile',
      type: 'memberProfile',
      text: 'View Member Profile',
      size: 12,
      sort: false,
    }, {
      prop: 'visitDateTime',
      title: 'Visit Date',
      type: 'DateTime'
    }, {
      prop: 'reportDateTime',
      title: 'Contact Date',
      mobileTitle: 'Received: ',
      mobile: 'subtitle',
      type: 'reportReceivedDate'
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'priority',
      title: 'Priority',
      mobile: 'subtext',
      size: 8
    }, {
      prop: 'contactRequired',
      title: 'Contact Required'
    }, {
      prop: 'status',
      title: 'Contact Status'
    }];

    if (membershipColumnEnabled) {
      let membershipColumn = {
        prop: 'memberStatus',
        title: 'Member Status',
        type: 'membership',
        displayCfaOne: true,
        sort: false
      };
      columns.splice(5, 0, membershipColumn);
    }
    return columns;
  }

  public static get spotCustomerColumnsNewWithOutRedFlag(): any[] {
    return [{
      title: 'Member Profile',
      type: 'memberProfile',
      text: 'View Member Profile',
      size: 12,
      sort: false,
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'lastTransactionDate',
      title: 'Last Visit Date',
      mobile: 'subtitle',
      type: 'lastVisitDate'
    }, {
      prop: 'birthDate',
      title: 'Birthday',
      type: 'birthDate'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      mobile: 'subtext'
    }];
  }

  public static get spotCustomerColumnsNewWithRedFlag(): any[] {
    return [{
      title: 'Member Profile',
      type: 'memberProfile',
      text: 'View Member Profile',
      size: 12,
      sort: false,
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      prop: 'lastTransactionDate',
      title: 'Last Visit Date',
      mobile: 'subtitle',
      type: 'lastVisitDate'
    }, {
      prop: 'birthDate',
      title: 'Birthday',
      type: 'birthDate'
    }, {
      prop: 'membership',
      title: 'Member Status',
      type: 'membership',
      mobile: 'subtext'
    },{
      prop: 'refundRedFlagged',
      title: 'Flagged',
      type: 'redFlag',
    }];
  }


  public static get digitalRefundsFlag(): string{
    return "DigitalRefundsFlagRecover";
  }

  public static get noHistoryMessage(): string {
    return 'No activity history found for this incident.';
  }

  public static get activityHistory(): string {
    return 'activityHistory';
  }

  public static get syncReport(): string {
    return 'syncReport';
  }

  public static get closedSubtype(): string {
    return 'closed';
  }

  public static get resolvedSubtype(): string {
    return 'resolved';
  }

  public static get withCareOffer(): string {
    return 'With Care Offer';
  }

  public static get withSpotlightOffer(): string {
    return 'With Spotlight Offer';
  }

  public static get noResultsMessage(): string {
    return 'There were no reports found for the requested criteria.';
  }

  public static get noSearchResultMessage(): string {
    return "We couldn't find any results for ";
  }

  public static get headerCopyText(): string {
    return 'Messaging';
  }

  public static get emailCopyText(): string {
    return 'Email Message';
  }

  public static get rewardType(): string {
    return 'reward';
  }

  public static get emailType(): string {
    return 'email';
  }

  public static get disableSendingFlag(): string {
    return 'disableRecoverSend';
  }

  public static get membershipColumnFlag(): string {
    return 'careMembershipColumn';
  }

  public static get sendSuccessMessage(): string {
    return 'Response Successfully Sent';
  }

  public static get sendErrorMessage(): string {
    return 'Response Failed to Send';
  }

  public static get prioritySendDisabledText(): string {
    return 'This is a priority issue, or Customer did not provide email address. ' +
      'Please correspond with Customer by phone, and document action taken.';
  }

  public static get allSendDisabledText(): string {
    return 'Reward and email sending have been temporarily disabled due to a ' +
      'critical issue that the team is working diligently to resolve.';
  }

  public static get ineligibleHeaderText(): string {
    return 'This customer has been recovered too many times';
  }

  public static get ineligibleBodyText(): string {
    return 'It looks like you\'ve recovered this customer 10 times within the past 90 days. ' +
      'Consider sending a DOC or investigating possible fraud instead.';
  }

  public static get ineligibleButtonText(): string {
    return 'Okay';
  }

  public static getResolvedWarningText(status: string, name: string): string {
    return `This will update the contact status to “${status}“, close the contact, and remove
      ${name} from the Recover tab.`;
  }

  public static getSendStatusSelectionHeadline(): string {
    return 'What action do you want to take on this item?';
  }

  public static getSendStatusSelectionResolveText(): string {
    return 'Resolve This Issue';
  }

  public static getSendStatusSelectionResolveSubtext(
    name: string, includeReward: boolean = false): string {
    let strEndStatement = includeReward ? ' with offer' : '';
    return `This will close the issue and remove "${name}" from the Recover tab and` +
      ` update the contact status to "` + this.getResolvedEmailStatusText() + strEndStatement + `"`;
  }

  public static getSendStatusSelectionKeepOpenText(): string {
    return 'Keep Issue Open';
  }

  public static getSendStatusSelectionKeepOpenSubtext(includeReward: boolean = false): string {
    let strEndStatement = includeReward ? ' with offer' : '';
    return 'This will keep the issue open and update the contact status to "' +
      this.getOpenEmailStatusText() + strEndStatement + '"';
  }

  public static getSendStatusSelectionCancelText(): string {
    return 'Cancel';
  }

  public static getSendStatusSelectionConfirmText(): string {
    return 'Send Email';
  }

  public static getResolvedRewardStatusText(): string {
    return 'Resolved: Sent Spotlight Email With Offer';
  }

  public static getResolvedEmailStatusText(): string {
    return 'Resolved: Sent Spotlight Email';
  }

  public static getOpenEmailStatusText(): string {
    return 'In Progress: Sent Spotlight Email';
  }

  public static get maxRewardSelection(): number {
    return 5;
  }

  public static get maxRewardSelectionText(): string {
    return `Select up to ${this.maxRewardSelection} Care Offers to send to Customer`;
  }

  public static get maxSpotRewardSelectionText(): string {
    return `Select up to ${this.maxRewardSelection} Spotlight Offers to send to Customer`;
  }

  public static get noSelectedCustomersText(): string {
    return 'No customers selected';
  }

  public static get spotConfirmationText(): string {
    return `The delivery of this message is in progress. Spotlight Recover messages are not displayed on Calendar.
      Delivery status will update shortly on the member's individual profile in the Restaurant Recovery section.`;
  }

  public static get emailTemplateBottomSheetData(): any {
    return {
      'title': 'Select your Email Template',
      'list': [],
      'isCancel': true
    };
  }

  public static get nonSpotlightMemberText(): any {
    return 'Because this Chick-fil-A One member is not in your Spotlight, template messaging cannot be edited';
  }

  public static get searchByCategories(): any {
    return [{
      'defaultCategory': true,
      'title': 'Customer Name',
      'value': 'customerName'
    },
    {
      'defaultCategory': false,
      'exampleText': 'Example `1234567`',
      'title': 'Sync Report Number',
      'value': 'syncReportNumber'
    }]
  }

  public static get searchPlaceHolderForDesktopCARESContacts(): string {
    return 'Search CARES Contacts';
  }

  public static get fourDigitMembershipNumberTitle(): string {
    return 'Last 4 Digits of Membership Number';
  }

  public static get fullMembershipNumberTitle(): string {
    return 'Full 14-Digit Membership Number';
  }

  public static get membershipNumberValue(): string {
    return 'membershipNumber';
  }

  public static get customerEmailTitle(): string {
    return 'Full Email Address';
  }

  public static get customerEmailValue(): string {
    return 'customerEmail';
  }

  public static get phoneNumberTitle(): string {
    return '10-Digit Phone Number';
  }

  public static get phoneNumberValue(): string {
    return 'phoneNumber';
  }

  public static get spotlightContactsSearchByCategories(): any[] {
    return [{
      defaultCategory: true,
      title: 'Customer Name',
      value: 'customerName'
    }, {
      defaultCategory: false,
      exampleText: 'Example `4047658000`',
      minLength: 10,
      title: this.phoneNumberTitle,
      value: this.phoneNumberValue
    }, {
      defaultCategory: false,
      errorText: 'Please enter a valid email address to continue',
      exampleText: 'Example `cows@cfacorp.com`',
      exampleTextNote: 'Note: Due to privacy restrictions, email is searchable but will not display.',
      title: this.customerEmailTitle,
      value: this.customerEmailValue
    }, {
      defaultCategory: false,
      exampleText: 'Example `1234`',
      maxLength: 4,
      minLength: 4,
      numbersOnly: true,
      title: this.fourDigitMembershipNumberTitle,
      value: this.membershipNumberValue
    }, {
      defaultCategory: false,
      exampleText: 'Example `89001123456789`',
      maxLength: 14,
      minLength: 14,
      numbersOnly: true,
      title: this.fullMembershipNumberTitle,
      value: this.membershipNumberValue
    }];
  }

  public static get spotlightContactsPageSizeOptions(): number[] {
    return [5, 10, 15];
  }

  public static get spotlightContactsDefaultPageSize(): number {
    return 5;
  }

  public static get spotlightContactsNoResultsMessage(): string {
    return 'There were no customers found for the requested criteria.';
  }

  public static get searchPlaceHolderForDesktopSpotlightContacts(): string {
    return 'Search Chick-fil-A One Members';
  }

  public static get defaultSpotlightContactsSortOrder(): string {
    return 'asc';
  }

  public static get defaultSpotlightContactsSortBy(): string {
    return 'firstName';
  }

  public static get unsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get unsavedChangesText(): string {
    return 'Would you like to apply the changes you\'ve made to this message?';
  }

  public static get unsavedChangesIcon(): string {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/UnsavedChanges_Icon.png';
  }

  public static get unsavedChangesConfirmButton(): string {
    return 'Apply';
  }

  public static get unsavedChangesCancelButton(): string {
    return 'Discard Changes';
  }

  public static get NoteNoResultsMessage(): string {
    return 'No customer notes found.';
  }

  public static get NoteErrorMessage(): string {
    return 'There was an error loading notes. Please try again later.';
  }

  public static get noteUnsavedChangesTitle(): string {
    return 'You have unsaved changes';
  }

  public static get noteUnsavedChangesText(): string {
    return 'Closing this note will discard any changes you\'ve made. Are you sure you want to continue?';
  }

  public static get noteUnsavedChangesConfirmButton(): string {
    return 'Go Back';
  }

  public static get noteSubText(): string {
    return 'This note will also appear on the customer’s member profile if they are part of your Spotlight.';
  }

  public static get deleteTitle() {
    return 'Would you like to delete this note?';
  }

  public static get deleteText() {
    return 'This action is permanent and you will not be able to recover data.';
  }

  public static get deleteIcon() {
    return 'https://www.cfacdn.com/img/Spotlight/Icons/Delete_RedIcon.png';
  }

  public static get confirmDeleteText() {
    return 'Delete';
  }

  public static get cancelDeleteText() {
    return 'No, thanks';
  }

  public static get searchPlaceHolderForDesktopClosedCareContacts(): string {
    return 'Search Closed Contacts';
  }

  public static get ClosedCareContactsPageSizeOptions(): number[] {
    return [10, 20, 30];
  }

  public static get defaultClosedCareContactsPageSize(): number {
    return 10;
  }

  public static get defaultClosedCareContactsSortOrder(): string {
    return 'asc';
  }

  public static get defaultClosedCareContactsSortBy(): string {
    return 'priority';
  }

  public static get closedCareContactsColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Name',
      mobile: 'title',
      hide: true
    }, {
      title: 'Sync Report',
      type: 'syncReport',
      text: 'View',
      id: this.syncReport,
      size: 9,
      sort: false,
      rowConcatProp: 'incidentId',
    }, {
      prop: 'showCustomerProfile',
      title: 'Member Profile',
      type: 'memberProfile',
      text: 'View Member Profile',
      size: 12,
      sort: false,
    }, {
      prop: 'visitDateTime',
      title: 'Visit Date',
      type:  'DateTime'
    }, {
      prop: 'reportDateTime',
      title: 'Contact Date',
      mobileTitle: 'Contact Date: ',
      mobile: 'subtitle',
      type: 'reportReceivedDate'
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'priority',
      title: 'Priority',
      size: 8,
      mobile: 'subtext'
    }, {
      prop: 'contactRequired',
      title: 'Contact Required'
    }, {
      prop: 'status',
      title: 'Contact Status'
    }];
  }

  public static get closedCareContactsNoResultsMessage(): string {
    return 'There are no Closed Care Contacts Statistics for this location.';
  }

  public static get noClosedCareContactsSearchResultMessage(): string {
    return "We're sorry, we couldn't find any results for ";
  }

  public static get recoveredCfaOneContactsSearchByCategories(): any[] {
    return [{
      defaultCategory: true,
      title: 'Customer Name',
      value: 'customerName'
    }/*, {
      defaultCategory: false,
      errorText: 'Only number inputs are allowed',
      exampleText: 'Example `4047658000`',
      title: this.phoneNumberTitle,
      value: this.phoneNumberValue
    }, {
      defaultCategory: false,
      errorText: 'Please enter a valid email address',
      exampleText: 'Example `cows@cfacorp.com`',
      exampleTextNote: 'Note: Due to privacy restrictions, email is searchable but will not display.',
      title: this.customerEmailTitle,
      value: this.customerEmailValue
    }, {
      defaultCategory: false,
      errorText: 'Only number inputs are allowed',
      exampleText: 'Example `1234`',
      title: this.fourDigitMembershipNumberTitle,
      value: this.membershipNumberValue
    }, {
      defaultCategory: false,
      errorText: 'Only number inputs are allowed',
      exampleText: 'Example `89001123456789`',
      title: this.fullMembershipNumberTitle,
      value: this.membershipNumberValue
    }*/];
  }

  public static get searchPlaceHolderForDesktopRecoveredCfaOneContacts(): string {
    return 'Search Recovered Contacts';
  }

  public static get recoveredCfaOneContactsColumns(): any[] {
    return [{
      prop: 'fullName',
      title: 'Full Name',
      mobile: 'title',
      hide: true
    }, {
      title: 'Member Profile',
      type: 'memberProfile',
      text: 'View Member Profile',
      size: 12,
      sort: false,
      prop: 'showCustomerProfile'
    }, {
      prop: 'recoveredDate',
      title: 'Date Recovered',
      mobile: 'subtitle',
      type: 'recoveredDate'
    }, {
      prop: 'firstName',
      title: 'First Name'
    }, {
      prop: 'lastName',
      title: 'Last Name'
    }, {
      prop: 'contactStatus',
      title: 'Contact Status',
      mobile: 'subtext'
    }];
  }

  public static get recoveredCfaOneContactsPageSizeOptions(): number[] {
    return [10, 20, 30];
  }

  public static get recoveredCfaOneContactsDefaultPageSize(): number {
    return 10;
  }

  public static get recoveredCfaOneContactsNoResultsMessage(): string {
    return 'No recovered Chick-fil-A One customers found.';
  }

  public static get recoveredCfaOneContactsNoSearchResultMessage(): string {
    return "We're sorry, we couldn't find any results for ";
  }

  public static get defaultRecoveredCfaOneContactsSortOrder(): string {
    return 'asc';
  }

  public static get defaultRecoveredCfaOneContactsSortBy(): string {
    return 'recoveredDate';
  }

  public static get recoveredCfaOneContactsEnabled(): string {
    return 'recoveredCfaOneContactsEnabled';
  }

  public static get careContactsBanner(): string {
    return 'care-contacts-banner';
  }

  public static get closedCareContactsBanner(): string {
    return 'closed-care-contacts-banner';
  }

  public static get cfaOneContactsBanner(): string {
    return 'cfaone-contacts-banner';
  }
  public static get closedCfaOneContactsBanner(): string {
    return 'closed-cfaone-contacts-banner';
  }

  public static get recoverAnalyticsBanner(): string {
    return 'recover-analytics-banner';
  }

  public static get activityHistoryNoteError(): string {
    return 'We were unable to save your note. Please try again later.';
  }

  public static get limitedTimeCareOfferFlag(): string {
    return 'limitedTimeCareOffer';
  }

  public static get caresContactsTabIndex(): number {
    return 0;
  }

  public static get closedCaresContactsTabIndex(): number {
    return 1;
  }

  public static get chickFilAOneContactsTabIndex(): number {
    return 2;
  }

  public static get closedChickFilAOneContactsTabIndex(): number {
    return 3;
  }

  public static get recoverAnalyticsTabIndex(): number {
    return 4;
  }

  public static get analyticsCareCategory(): string {
    return 'care';
  }

  public static get caresReportViewOptions(): string [] {
    return ['Last 30 Days', 'Last 60 Days', 'Last 90 Days']
  }

  public static get acknowledgeReportDialogText(): string {
    return 'The high priority report has been acknowledged.';
  }

  public static get reassignReportDialogText(): string {
    return 'A request to reassign this report was sent out.';
  }

  public static get highPriority(): string {
    return 'HIGH';
  }
}
