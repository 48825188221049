import * as care from '../actions/care';
import * as layout from '../actions/layout';
import * as user from '../actions/user';
import { CareConstants } from '../care/care.constants';
import { clone, find, findIndex, propEq } from 'ramda';
import { ServiceNowCase } from '../models/recover/ServiceNowCase';

export interface State {
  allCustomerIds: any[];
  careSelectedTabIndex: number;
  careSelectedView: string;
  cfaOneSelectedView: string;
  closedCareContacts: any[];
  closedCareContactsError: any;
  closedCareContactsLoading: boolean;
  closedCareContactsNumberOfPages: number;
  closedCareContactsPage: number;
  closedCareContactsPageSize: number;
  closedCareContactsSearchBy: any;
  closedCareContactsSearchTerm: any;
  closedCareContactsSortBy: string;
  closedCareContactsSortOrder: string;
  closedCareContactsTotalElements: number;
  closedCareSelectedView: string;
  copy?: string;
  count: number;
  customerNotes: any;
  customerNotesError: any;
  customerNotesLoading: boolean;
  customerNotesToDisplay: any;
  customers: any[];
  dateSent?: any;
  defaultCopy?: string;
  defaultThemeName?: string;
  emailThemes: any[];
  error?: any;
  headline?: string;
  includeReward?: boolean;
  isResolved?: boolean;
  limit: number;
  loading: boolean;
  memberType?: string;
  numberOfPages: number;
  openingStatement?: string;
  page: number;
  pageSize: number;
  preHeader?: string;
  recoveredCfaOneContacts: any[];
  recoveredCfaOneContactsCurrentPage: number;
  recoveredCfaOneContactsDisplayErrorText: boolean;
  recoveredCfaOneContactsError: any[];
  recoveredCfaOneContactsLoading: boolean;
  recoveredCfaOneContactsNumberOfPages: number;
  recoveredCfaOneContactsPageSize: number;
  recoveredCfaOneContactsSearchBy: any;
  recoveredCfaOneContactsSearchTerm: string;
  recoveredCfaOneContactsSortBy: string;
  recoveredCfaOneContactsSortOrder: string;
  recoveredSelectedView: string;
  recoverEligibilityLoading: boolean;
  reports: any[];
  resolutionStatus?: string;
  rewardCategories?: any[];
  searchBy: any;
  searchTerm: any;
  selectedCustomer: any;
  selectedReport: any;
  selectedRewards: any[];
  serviceNowCase: ServiceNowCase;
  serviceNowCaseError: string;
  serviceNowCaseLoading: boolean;
  shouldLoadRecoveredCfaOneContacts: boolean;
  sortBy: string;
  sortOrder: string;
  spotlightContactsDisplayErrorText: boolean;
  spotlightContactsError: any[];
  spotlightContactsLoading: boolean;
  spotlightContactsNumberOfPages: number;
  spotlightContactsPage: number;
  spotlightContactsSearchBy: any;
  spotlightContactsSearchTerm: any;
  spotlightContactsSortBy: string;
  spotlightContactsSortOrder: string;
  statusOptions: any[];
  subject?: string;
  templateError: string;
  templateHtml?: string;
  templateId?: string;
  templateLoading: boolean;
  themeName?: string;
  themeUrl?: string;
  totalNumberOfRecoveredCfaOneContacts: number;
  totalNumberOfReports: number;
}

export const initialState: State = {
  allCustomerIds: [],
  careSelectedTabIndex: null,
  careSelectedView: CareConstants.caresReportViewOptions[0],
  cfaOneSelectedView: CareConstants.caresReportViewOptions[0],
  closedCareContacts: [],
  closedCareContactsError: null,
  closedCareContactsLoading: false,
  closedCareContactsNumberOfPages: 0,
  closedCareContactsPage: 0,
  closedCareContactsPageSize: CareConstants.defaultClosedCareContactsPageSize,
  closedCareContactsSearchBy: CareConstants.searchByCategories[0].value,
  closedCareContactsSearchTerm: null,
  closedCareContactsSortBy: CareConstants.defaultClosedCareContactsSortBy,
  closedCareContactsSortOrder: CareConstants.defaultClosedCareContactsSortOrder,
  closedCareContactsTotalElements: 0,
  closedCareSelectedView: CareConstants.caresReportViewOptions[0],
  count: 0,
  customerNotes: [],
  customerNotesError: null,
  customerNotesLoading: false,
  customerNotesToDisplay: [],
  customers: [],
  emailThemes: [],
  limit: CareConstants.spotlightContactsDefaultPageSize,
  loading: true,
  numberOfPages: 0,
  page: 0,
  pageSize: CareConstants.defaultPageSize,
  recoveredCfaOneContacts: [],
  recoveredCfaOneContactsCurrentPage: 0,
  recoveredCfaOneContactsDisplayErrorText: false,
  recoveredCfaOneContactsError: null,
  recoveredCfaOneContactsLoading: false,
  recoveredCfaOneContactsNumberOfPages: 0,
  recoveredCfaOneContactsPageSize: CareConstants.recoveredCfaOneContactsDefaultPageSize,
  recoveredCfaOneContactsSearchBy: CareConstants.recoveredCfaOneContactsSearchByCategories[0].value,
  recoveredCfaOneContactsSearchTerm: null,
  recoveredCfaOneContactsSortBy: CareConstants.defaultRecoveredCfaOneContactsSortBy,
  recoveredCfaOneContactsSortOrder: CareConstants.defaultRecoveredCfaOneContactsSortOrder,
  recoveredSelectedView: CareConstants.caresReportViewOptions[0],
  recoverEligibilityLoading: true,
  reports: null,
  searchBy: CareConstants.searchByCategories[0].value,
  searchTerm: null,
  selectedCustomer: null,
  selectedReport: {
    activityHistory: [],
    historyLoading: true,
    historyError: null
  },
  selectedRewards: [],
  serviceNowCase: null,
  serviceNowCaseError: null,
  serviceNowCaseLoading: true,
  shouldLoadRecoveredCfaOneContacts: false,
  sortBy: CareConstants.defaultSortBy,
  sortOrder: CareConstants.defaultSortOrder,
  spotlightContactsDisplayErrorText: false,
  spotlightContactsError: null,
  spotlightContactsLoading: false,
  spotlightContactsNumberOfPages: 0,
  spotlightContactsPage: 0,
  spotlightContactsSearchBy: CareConstants.searchByCategories[0].value,
  spotlightContactsSearchTerm: '',
  spotlightContactsSortBy: CareConstants.defaultSpotlightContactsSortBy,
  spotlightContactsSortOrder: CareConstants.defaultSpotlightContactsSortOrder,
  statusOptions: null,
  templateError: null,
  templateLoading: true,
  totalNumberOfRecoveredCfaOneContacts: 0,
  totalNumberOfReports: 0
};

export function reducer(state = initialState, action: care.Actions | layout.Actions | user.Actions): State {
  switch (action.type) {
    case care.ActionTypes.CARE_CHANGE_VIEW: {
      const careSelectedView = action.payload;
      return {
        ...state,
        careSelectedView,
        loading: true
      };
    }

    case care.ActionTypes.CARE_SET_SELECTED_TAB_INDEX: {
      const careSelectedTabIndex = action.payload;
      return { ...state, careSelectedTabIndex };
    }

    case care.ActionTypes.CFA_ONE_CHANGE_VIEW: {
      const cfaOneSelectedView = action.payload;
      return {
        ...state,
        cfaOneSelectedView,
        spotlightContactsError: null,
        spotlightContactsLoading: true
      };
    }

    case care.ActionTypes.CLEAR_SEARCH_TERM: {
      const searchTerm = null;
      return {
        ...state,
        loading: true,
        page: 0,
        searchTerm
      };
    }

    case care.ActionTypes.CLOSE_ACTION_HISTORY_NOTE: {
      return {
        ...state,
        selectedReport: {
          ...state.selectedReport,
          openActionHistoryNote: false
        }
      };
    }

    case care.ActionTypes.CLOSE_ALL_OPEN_NOTES: {
      let activityHistory = clone(state.selectedReport.activityHistory);
      let customerNotes = clone(state.customerNotes);
      let customerNotesToDisplay = [];
      const indexOfEdit = findIndex(propEq('isEditMode', true))(customerNotes);
      const indexOfNewNote = findIndex(propEq('isNewNote', true))(customerNotes);

      // If an existing note is being edited, close it
      if (indexOfEdit > -1) {
        customerNotes[indexOfEdit].isEditMode = false;
      }

      // If there is an open new note, close it
      if (indexOfNewNote > -1) {
        customerNotes.shift();
      }

      // Only show the first 5 notes
      if (customerNotes.length > 5) {
        customerNotesToDisplay = customerNotes.slice(0, 5);
      } else {
        customerNotesToDisplay = customerNotes.slice(0, customerNotes.length);
      }

      // Close any open action history notes
      activityHistory.map((activity) => {
        if (activity && activity.recoveryNotes) {
          activity.recoveryNotes.map((note, noteIndex) => {
            // Close any unsaved new notes
            if (noteIndex === 0 && note && note.isNewNote) {
              activity.recoveryNotes.shift();
            }
            // Close any other note that is currently being edited
            if (note && note.isEditMode) {
              note.isEditMode = false;
            }
          });
        }
      });

      return {
        ...state,
        customerNotes,
        customerNotesToDisplay,
        selectedReport: {
          ...state.selectedReport,
          activityHistory,
          openActionHistoryNote: false,
          openCustomerNote: false
        }
      };
    }

    case care.ActionTypes.CLOSE_CUSTOMER_NOTE: {
      return {
        ...state,
        selectedReport: {
          ...state.selectedReport,
          openCustomerNote: false
        }
      };
    }

    case care.ActionTypes.CLOSED_CARE_CHANGE_VIEW: {
      const closedCareSelectedView = action.payload;

      return {
        ...state,
        closedCareContactsLoading: true,
        closedCareSelectedView
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE: {
      const closedCareContactsPage = action.payload;

      return {
        ...state,
        closedCareContactsError: null,
        closedCareContactsLoading: true,
        closedCareContactsPage
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_PAGE_SIZE: {
      const closedCareContactsPageSize = action.payload;

      return {
        ...state,
        closedCareContactsError: null,
        closedCareContactsLoading: true,
        closedCareContactsPage: 0,
        closedCareContactsPageSize
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_CHANGE_SORT: {
      const payload = action.payload;

      return {
        ...state,
        closedCareContactsError: null,
        closedCareContactsLoading: true,
        closedCareContactsSortBy: payload.prop,
        closedCareContactsSortOrder: payload.order
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_CLEAR_SEARCH_TERM: {
      return {
        ...state,
        closedCareContactsLoading: true,
        closedCareContactsSearchTerm: null
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        closedCareContacts: [],
        closedCareContactsError: error,
        closedCareContactsLoading: false,
        closedCareContactsNumberOfPages: 0,
        closedCareContactsTotalElements: 0
      }
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH: {
      const closedCareContactsSearchTerm = action.payload;
      return {
        ...state,
        closedCareContactsLoading: true,
        closedCareContactsSearchTerm
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_SEARCH_BY: {
      const closedCareContactsSearchBy = action.payload;
      const closedCareContactsSearchTerm = '';
      return {
        ...state,
        closedCareContactsLoading: true,
        closedCareContactsSearchBy,
        closedCareContactsSearchTerm
      };
    }

    case care.ActionTypes.CLOSED_CARE_CONTACTS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        closedCareContacts: payload.data,
        closedCareContactsError: null,
        closedCareContactsLoading: false,
        closedCareContactsNumberOfPages: payload.numberOfPages,
        closedCareContactsTotalElements: payload.totalElements
      }
    }

    case care.ActionTypes.CREATE_ACTION_HISTORY_NOTE:
    case care.ActionTypes.UPDATE_ACTION_HISTORY_NOTE: {
      return {
        ...state,
        selectedReport: {
          ...state.selectedReport,
          historyLoading: true
        }
      };
    }

    case care.ActionTypes.CREATE_ACTION_HISTORY_NOTE_ERROR:
    case care.ActionTypes.UPDATE_ACTION_HISTORY_NOTE_ERROR: {
      const noteError = CareConstants.activityHistoryNoteError;
      let recordIndex, noteIndex;
      let updatedSelectedReport = state.selectedReport;

      // Determine which note errored out based on isEditMode being true
      for (let i = 0; i < updatedSelectedReport.activityHistory.length; i++) {
        const numberOfNotes = updatedSelectedReport.activityHistory[i].recoveryNotes
          ? updatedSelectedReport.activityHistory[i].recoveryNotes.length : 0;

        for (let j = 0; j < numberOfNotes; j++) {
          if (updatedSelectedReport.activityHistory[i].recoveryNotes[j].isEditMode) {
            recordIndex = i;
            noteIndex = j;
          }
        }
      }

      if (updatedSelectedReport.activityHistory[recordIndex]
          && updatedSelectedReport.activityHistory[recordIndex].recoveryNotes[noteIndex]) {
        updatedSelectedReport.activityHistory[recordIndex].recoveryNotes[noteIndex].error = noteError;
      }

      return {
        ...state,
        selectedReport: {
          ...updatedSelectedReport,
          historyLoading: false
        }
      };
    }

    case care.ActionTypes.CREATE_ACTION_HISTORY_NOTE_SUCCESS: {
      const note = action.payload;
      let updatedSelectedReport = state.selectedReport;
      const recordIndex = findIndex(propEq('activityId', note.activityId))(state.selectedReport.activityHistory);
      updatedSelectedReport.activityHistory[recordIndex].recoveryNotes.shift();
      updatedSelectedReport.activityHistory[recordIndex].recoveryNotes.push(note);

      return {
        ...state,
        selectedReport: {
          ...updatedSelectedReport,
          historyLoading: false,
          openActionHistoryNote: false
        }
      };
    }

    case care.ActionTypes.CREATE_CUSTOMER_NOTE:
    case care.ActionTypes.DELETE_CUSTOMER_NOTE:
    case care.ActionTypes.UPDATE_CUSTOMER_NOTE:{
      return {
        ...state,
        customerNotesLoading: true
      };
    }

    case care.ActionTypes.CUSTOMER_NOTE_ACTION_COMPLETE: {
      let { noteId } = action.payload;
      let data = state.customerNotes;
      const selectedIndex = findIndex(propEq('noteId', noteId))(data);

      if (selectedIndex > -1) {
        data[selectedIndex].error = null;
      }

      return {
        ...state,
        customerNotes: data,
        selectedReport: {
          ...state.selectedReport,
          openCustomerNote: false
        }
      };
    }

    case care.ActionTypes.CUSTOMER_NOTE_ERROR: {
      let { noteId, err } = action.payload;
      let data = state.customerNotes;
      const selectedIndex = findIndex(propEq('noteId', noteId))(data);

      if (data[selectedIndex]) {
        data[selectedIndex].error = err;
      }

      return {
        ...state,
        customerNotes: data,
        customerNotesLoading: false
      };
    }

    case care.ActionTypes.DECREMENT_REWARD: {
      const reward = action.payload;
      let selectedRewards = clone(state.selectedRewards);
      const rewardIndex = findIndex(propEq('id', reward.id))(selectedRewards);
      selectedRewards[rewardIndex].count--;

      // Remove from selected rewards if it now has a count of 0
      if (selectedRewards[rewardIndex].count === 0) {
        selectedRewards.splice(rewardIndex, 1);
      }

      return { ...state, selectedRewards };
    }

    case care.ActionTypes.EDIT_CUSTOMER_NOTE: {
      const payload = action.payload;
      const activityHistory = clone(state.selectedReport.activityHistory);
      let customerNotes = clone(state.customerNotes);
      let customerNotesToDisplay = [];
      const indexOfEdit = findIndex(propEq('isEditMode', true))(customerNotes);
      const noteIndex = findIndex(propEq('noteId', payload.noteId))(customerNotes);
      const indexOfNewNote = findIndex(propEq('isNewNote', true))(customerNotes);

      // If an existing note is being edited, close it
      if (indexOfEdit > -1) {
        customerNotes[indexOfEdit].isEditMode = false;
      }

      customerNotes[noteIndex].isEditMode = true;
      customerNotes[noteIndex].updatedText = customerNotes[noteIndex].note;

      // If there is an open new note, close it
      if (indexOfNewNote > -1) {
        customerNotes.shift();
      }

      // Set notes to display based on current page and page size
      const nextRowIndex = payload.currentPage * payload.pageSize;
      if (nextRowIndex + payload.pageSize < customerNotes.length) {
        customerNotesToDisplay = customerNotes.slice(nextRowIndex, nextRowIndex + payload.pageSize);
      } else {
        customerNotesToDisplay = customerNotes.slice(nextRowIndex, customerNotes.length);
      }

      // Close any open action history notes
      for (let i = 0; i < activityHistory.length; i++) {
        const numberOfNotes = activityHistory[i].recoveryNotes ? activityHistory[i].recoveryNotes.length : 0;

        for (let j = 0; j < numberOfNotes; j++) {
          // Close any unsaved new notes
          if (j === 0 && activityHistory[i].recoveryNotes[j] && activityHistory[i].recoveryNotes[j].isNewNote) {
            activityHistory[i].recoveryNotes.shift();
          }
          // Close any other note that is currently being edited
          if (activityHistory[i].recoveryNotes[j]) {
            activityHistory[i].recoveryNotes[j].isEditMode = false;
          }
        }
      }

      return {
        ...state,
        customerNotes,
        customerNotesToDisplay,
        selectedReport: {
          ...state.selectedReport,
          activityHistory,
          openActionHistoryNote: false,
          openCustomerNote: true
        }
      };
    }

    case care.ActionTypes.ERROR: {
      const payload = action.payload;
      return {
        ...state,
        customerNotesError: payload.err,
        customerNotesLoading: false
      };
    }

    case care.ActionTypes.FETCH_TEMPLATE_ERROR: {
      const templateError = action.payload;

      return { ...state, templateError, templateLoading: false };
    }

    case care.ActionTypes.FETCH_TEMPLATE_SUCCESS: {
      const template = action.payload;
      const emailThemes = template.emailThemes;

      // Set the fields from the first theme in the list if no default is given
      // This assumes we are at least getting a theme returned. If none are this will break
      const defaultTheme = find(propEq('defaultTheme', true))(emailThemes)
        ? find(propEq('defaultTheme', true))(emailThemes) : emailThemes[0];
      const defaultCopy = defaultTheme.emailCopy;
      const defaultThemeName = defaultTheme.themeName;
      const headline = defaultTheme.headLine;
      const preHeader = defaultTheme.preHeader;
      const subject = defaultTheme.subject;
      const themeUrl = defaultTheme.themeUrl;

      return {
        ...state,
        ...template,
        copy: defaultCopy,
        defaultCopy,
        defaultThemeName,
        emailThemes,
        headline,
        preHeader,
        selectedRewards: [],
        subject,
        templateLoading: false,
        themeName: defaultThemeName,
        themeUrl
      };
    }

    case care.ActionTypes.GET_ACTIVITY_HISTORY_ERROR: {
      const historyError = action.payload;

      return {
        ...state,
        selectedReport: {
          ...state.selectedReport,
          historyError,
          historyLoading: false
        }
      };
    }

    case care.ActionTypes.GET_ACTIVITY_HISTORY_SUCCESS: {
      const historyPayload = action.payload;
      let showCustomerProfile = historyPayload.showCustomerProfile;
      let activityHistory = historyPayload.careTicketHistory.map((record) => {
        let statusUpdateText;
        if (record.previousStatus) {
          statusUpdateText = `<b>${record.previousStatus}</b> to <b>${record.status}</b>`;
        } else {
          statusUpdateText = `<b>${record.status}</b>`;
        }

        return {
          ...record,
          statusUpdateText
        }
      });

      return {
        ...state,
        recoverEligibilityLoading: false,
        selectedReport: {
          ...state.selectedReport,
          activityHistory,
          historyLoading: false,
          showCustomerProfile
        }
      };
    }

    case care.ActionTypes.GET_CARE_EMAIL_DETAILS: {
      return {
        ...state,
        templateLoading: true
      };
    }

    case care.ActionTypes.GET_CARE_EMAIL_DETAILS_ERROR: {
      const templateError = action.payload;

      return {
        ...state,
        templateError,
        templateLoading: false
      };
    }

    case care.ActionTypes.GET_CARE_EMAIL_DETAILS_SUCCESS: {
      const emailDetails = action.payload;

      return {
        ...state,
        ...emailDetails,
        templateHtml: emailDetails.html,
        templateLoading: false
      };
    }

    case care.ActionTypes.GET_CARE_REPORT_DETAILS:
    case care.ActionTypes.REOPEN_REPORT_SUCCESS:
    case care.ActionTypes.UPDATE_STATUS: {
      return {
        ...state,
        customerNotesLoading: true,
        error: null,
        loading: true,
        selectedReport: {
          ...state.selectedReport,
          activityHistory: [],
          historyError: null,
          historyLoading: true
        }
      };
    }

    case care.ActionTypes.GET_CARE_REPORT_DETAILS_ERROR:
    case care.ActionTypes.GET_CARE_REPORTS_ERROR: {
      const error = action.payload;

      return {
        ...state,
        customerNotesError: error,
        customerNotesLoading: false,
        error,
        loading: false,
        selectedReport: {
          historyError: error,
          historyLoading: false
        }
      };
    }

    case care.ActionTypes.GET_CARE_REPORT_DETAILS_SUCCESS: {
      const { recoveryGuest, statusOptions } = action.payload;

      return {
        ...state,
        error: null,
        loading: false,
        selectedReport: {
          ...state.selectedReport,
          ...recoveryGuest
        },
        statusOptions
      };
    }

    case care.ActionTypes.GET_CARE_REPORTS_SUCCESS: {
      const { totalElements, recoveryGuests, numberOfPages, statusOptions } = action.payload;

      return {
        ...state,
        error: state.error,
        loading: false,
        numberOfPages,
        reports: recoveryGuests,
        statusOptions,
        totalNumberOfReports: totalElements
      };
    }

    case care.ActionTypes.GET_CUSTOMER_NOTES_ERROR: {
      const payload = action.payload;

      return {
        ...state,
        customerNotesError: payload,
        customerNotesLoading: false
      };
    }

    case care.ActionTypes.GET_CUSTOMER_NOTES_SUCCESS: {
      const payload = (action.payload && action.payload.notes) || [];
      const customerNotesToDisplay = payload && payload.length > 4 ? payload.slice(0, 5) : payload;

      return {
        ...state,
        customerNotes: payload,
        customerNotesError: null,
        customerNotesLoading: false,
        customerNotesToDisplay
      };
    }

    case care.ActionTypes.GET_ELIGIBLE_FOR_RECOVERY_ERROR: {
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          eligibleForRecovery: false
        }
      };
    }

    case care.ActionTypes.GET_ELIGIBLE_FOR_RECOVERY_SUCCESS: {
      const eligibleForRecovery = action.payload;

      return {
        ...state,
        recoverEligibilityLoading: false,
        selectedCustomer: {
          ...state.selectedCustomer,
          eligibleForRecovery
        }
      };
    }

    case care.ActionTypes.INCREMENT_REWARD: {
      const reward = action.payload;
      let selectedRewards = clone(state.selectedRewards);
      const rewardIndex = findIndex(propEq('id', reward.id))(selectedRewards);

      if (rewardIndex > -1) {
        selectedRewards[rewardIndex].count++;
      } else {
        selectedRewards.push({ ...reward, count: 1 })
      }

      return { ...state, selectedRewards };
    }

    case care.ActionTypes.LOAD_CARES_PAGE:
    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      return {
        ...state,
        closedCareContactsLoading: true,
        error: null,
        loading: true,
        shouldLoadRecoveredCfaOneContacts: false
      };
    }

    case care.ActionTypes.LOAD_RECOVERED_CFA_ONE_CONTACTS_DATA: {
      const payload = action.payload;

      return {
        ...state,
        recoveredCfaOneContactsLoading: payload,
        shouldLoadRecoveredCfaOneContacts: payload
      }
    }

    case care.ActionTypes.OPEN_ACTION_HISTORY_NOTE: {
      let customerNotes = clone(state.customerNotes);
      let customerNotesToDisplay;

      // Close any open customer notes
      for (let i = 0; i < customerNotes.length; i++) {
        // Close any unsaved new notes
        if (i === 0 && customerNotes[0].isNewNote) {
          customerNotes.shift();
        }
        // Close any other note that is currently being edited
        if (customerNotes[i]) {
          customerNotes[i].isEditMode = false;
        }
      }

      // Update current notes to display after closing open ones
      if (customerNotes.length > 4) {
        customerNotesToDisplay = customerNotes.slice(0, 5);
      } else {
        customerNotesToDisplay = customerNotes;
      }

      return {
        ...state,
        customerNotes,
        customerNotesToDisplay,
        selectedReport: {
          ...state.selectedReport,
          openActionHistoryNote: true,
          openCustomerNote: false
        }
      };
    }

    case care.ActionTypes.OPEN_CUSTOMER_NOTE: {
      const payload = action.payload;
      const activityHistory = clone(state.selectedReport.activityHistory);
      let customerNotes = clone(state.customerNotes);
      let customerNotesToDisplay = [];
      const indexOfEdit = findIndex(propEq('isEditMode', true))(customerNotes);

      // If an existing note is being edited, close it
      if (indexOfEdit > -1) {
        customerNotes[indexOfEdit].isEditMode = false;
      }

      // Add a new empty note to the beginning of the array
      customerNotes.unshift({
        note: '',
        cfaUid: payload.customerId,
        updatedBy: payload.userName,
        updatedText: '',
        isNewNote: true,
        isEditMode: true
      });

      // Set current notes to display based on page size
      if (payload.pageSize < customerNotes.length) {
        customerNotesToDisplay = customerNotes.slice(0, payload.pageSize);
      } else {
        customerNotesToDisplay = customerNotes;
      }

      // Close any open action history notes
      for (let i = 0; i < activityHistory.length; i++) {
        const numberOfNotes = activityHistory[i].recoveryNotes ? activityHistory[i].recoveryNotes.length : 0;

        for (let j = 0; j < numberOfNotes; j++) {
          // Close any unsaved new notes
          if (j === 0 && activityHistory[i].recoveryNotes[j] && activityHistory[i].recoveryNotes[j].isNewNote) {
            activityHistory[i].recoveryNotes.shift();
          }
          // Close any other note that is currently being edited
          if (activityHistory[i].recoveryNotes[j]) {
            activityHistory[i].recoveryNotes[j].isEditMode = false;
          }
        }
      }

      return {
        ...state,
        customerNotes,
        customerNotesToDisplay,
        selectedReport: {
          ...state.selectedReport,
          activityHistory,
          openActionHistoryNote: false,
          openCustomerNote: true
        }
      };
    }

    case care.ActionTypes.OPEN_SERVICE_NOW_REPORT: {
      return { ...state, serviceNowCaseLoading: true };
    }

    case care.ActionTypes.OPEN_SERVICE_NOW_REPORT_ERROR: {
      const error = action.payload;
      return { ...state, serviceNowCaseError: error, serviceNowCaseLoading: false };
    }

    case care.ActionTypes.OPEN_SERVICE_NOW_REPORT_SUCCESS: {
      const report = action.payload;
      return { ...state, serviceNowCase: report, serviceNowCaseLoading: false };
    }

    case care.ActionTypes.PAGE_CHANGE: {
      const page = action.payload;

      return { ...state, loading: true, page };
    }

    case care.ActionTypes.PAGE_SIZE_CHANGE: {
      const pageSize = action.payload;

      return { ...state, loading: true, page: 0, pageSize };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CHANGE_VIEW: {
      const recoveredSelectedView = action.payload;

      return {
        ...state,
        recoveredCfaOneContactsLoading: true,
        recoveredSelectedView
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE: {
      return {
        ...state,
        recoveredCfaOneContactsCurrentPage: action.payload,
        recoveredCfaOneContactsError: null,
        recoveredCfaOneContactsLoading: true
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_PAGE_SIZE: {
      return {
        ...state,
        recoveredCfaOneContactsCurrentPage: 0,
        recoveredCfaOneContactsError: null,
        recoveredCfaOneContactsLoading: true,
        recoveredCfaOneContactsPageSize: action.payload
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CHANGE_SORT: {
      const payload = action.payload;

      return {
        ...state,
        recoveredCfaOneContactsError: null,
        recoveredCfaOneContactsLoading: true,
        recoveredCfaOneContactsSortBy: payload.prop,
        recoveredCfaOneContactsSortOrder: payload.order
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_CLEAR_SEARCH_TERM: {
      return {
        ...state,
        recoveredCfaOneContactsDisplayErrorText: false,
        recoveredCfaOneContactsLoading: true,
        recoveredCfaOneContactsSearchTerm: null
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_DISPLAY_ERROR_TEXT: {
      const payload = action.payload;

      return {
        ...state,
        recoveredCfaOneContactsDisplayErrorText: payload
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_ERROR: {
      const error = action.payload;
      return {
        ...state,
        recoveredCfaOneContacts: [],
        recoveredCfaOneContactsError: error,
        recoveredCfaOneContactsLoading: false,
        recoveredCfaOneContactsNumberOfPages: 0,
        totalNumberOfRecoveredCfaOneContacts: 0
      }
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH: {
      const recoveredCfaOneContactsSearchTerm = action.payload;
      return {
        ...state,
        recoveredCfaOneContactsDisplayErrorText: false,
        recoveredCfaOneContactsLoading: true,
        recoveredCfaOneContactsSearchTerm
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SEARCH_BY: {
      const recoveredCfaOneContactsSearchBy = action.payload;

      return {
        ...state,
        recoveredCfaOneContactsDisplayErrorText: false,
        recoveredCfaOneContactsLoading: true,
        recoveredCfaOneContactsSearchBy,
        recoveredCfaOneContactsSearchTerm: ''
      };
    }

    case care.ActionTypes.RECOVERED_CFA_ONE_CONTACTS_SUCCESS: {
      const payload = action.payload;

      return {
        ...state,
        recoveredCfaOneContacts: payload.cfaoneResolvedContacts,
        recoveredCfaOneContactsError: null,
        recoveredCfaOneContactsLoading: false,
        recoveredCfaOneContactsNumberOfPages: payload.numberOfPages,
        totalNumberOfRecoveredCfaOneContacts: payload.totalElements
      }
    }

    case care.ActionTypes.REOPEN_REPORT: {
      return {
        ...state,
        customerNotesLoading: true,
        loading: true,
        selectedReport: {
          ...state.selectedReport,
          historyLoading: true
        }
      };
    }

    case care.ActionTypes.REOPEN_REPORT_ERROR: {
      return {
        ...state,
        customerNotesLoading: false,
        loading: false,
        selectedReport: {
          ...state.selectedReport,
          historyLoading: false
        }
      };
    }

    case care.ActionTypes.SEARCH_BY: {
      const searchBy = action.payload;

      return {
        ...state,
        loading: true,
        page: 0,
        searchBy,
        searchTerm: ''
      };
    }

    case care.ActionTypes.SEARCH_TERM: {
      const searchTerm = action.payload;

      return {
        ...state,
        loading: true,
        page: 0,
        searchTerm
      };
    }

    case care.ActionTypes.SELECT_CUSTOMER: {
      const selectedCustomer = action.payload;

      return {
        ...state,
        recoverEligibilityLoading: true,
        selectedCustomer
      };
    }

    case care.ActionTypes.SELECT_REPORT: {
      const report = action.payload;

      return {
        ...state,
        customerNotesLoading: true,
        includeReward: null,
        loading: false,
        memberType: null,
        selectedCustomer: initialState.selectedCustomer,
        selectedReport: {
          ...initialState.selectedReport,
          ...report,
          historyLoading: true
        },
        selectedRewards: []
      };
    }

    case care.ActionTypes.SELECT_REWARDS: {
      const selectedRewards = action.payload;

      return { ...state, selectedRewards };
    }

    case care.ActionTypes.SELECT_TEMPLATE: {
      const { includeReward, memberType, note } = action.payload;
      const resolutionStatus = includeReward ? CareConstants.getResolvedRewardStatusText() : null;
      const isResolved = includeReward ? true : null;

      return {
        ...state,
        includeReward,
        isResolved,
        memberType,
        resolutionStatus,
        selectedReport: {
          ...state.selectedReport,
          note
        },
        templateLoading: true
      };
    }

    case care.ActionTypes.SEND_RESPONSE: {
      const { isResolved } = action.payload;
      let resolutionStatus = state.resolutionStatus;

      if (!state.includeReward) {
        resolutionStatus = isResolved ? CareConstants.getResolvedEmailStatusText() : CareConstants.getOpenEmailStatusText();
      }

      return {
        ...state,
        isResolved,
        loading: true,
        resolutionStatus,
        templateLoading: true
      };
    }

    case care.ActionTypes.SEND_RESPONSE_ERROR:
    case care.ActionTypes.SEND_RESPONSE_SUCCESS: {
      return initialState;
    }

    case care.ActionTypes.SORT_CHANGE: {
      const sortBy = action.payload.prop;
      const sortOrder = action.payload.order;

      return { ...state, loading: true, sortBy, sortOrder };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE: {
      const payload = action.payload;

      return {
        ...state,
        spotlightContactsError: null,
        spotlightContactsLoading: true,
        spotlightContactsPage: payload
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_PAGE_SIZE: {
      return {
        ...state,
        limit: action.payload,
        spotlightContactsError: null,
        spotlightContactsLoading: true,
        spotlightContactsPage: 0
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_CHANGE_SORT: {
      const payload = action.payload;

      return {
        ...state,
        spotlightContactsError: null,
        spotlightContactsLoading: true,
        spotlightContactsSortBy: payload.prop,
        spotlightContactsSortOrder: payload.order
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_CLEAR_SEARCH_TERM: {
      return {
        ...state,
        allCustomerIds: [],
        count: 0,
        customers: [],
        spotlightContactsDisplayErrorText: false,
        spotlightContactsNumberOfPages: 0,
        spotlightContactsPage: 0,
        spotlightContactsSearchTerm: ''
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_DISPLAY_ERROR_TEXT: {
      const spotlightContactsDisplayErrorText = action.payload;

      return { ...state, spotlightContactsDisplayErrorText };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH: {
      return {
        ...state,
        spotlightContactsDisplayErrorText: false,
        spotlightContactsError: null,
        spotlightContactsLoading: true,
        spotlightContactsPage: 0,
        spotlightContactsSearchTerm: action.payload
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_BY: {
      return {
        ...state,
        spotlightContactsDisplayErrorText: false,
        spotlightContactsPage: 0,
        spotlightContactsSearchBy: action.payload,
        spotlightContactsSearchTerm: ''
      };
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_COMPLETE: {
      const payload = action.payload;

      if (state.spotlightContactsSearchTerm) {
        return {
          ...state,
          allCustomerIds: payload.allCustomerIds,
          count: payload.totalElements,
          customers: payload.customerProfiles,
          spotlightContactsError: null,
          spotlightContactsLoading: false,
          spotlightContactsNumberOfPages: payload.numberOfPages
        };
      } else {
        return {
          ...state,
          allCustomerIds: [],
          count: 0,
          customers: [],
          spotlightContactsError: null,
          spotlightContactsLoading: false,
          spotlightContactsNumberOfPages: 0
        }
      }
    }

    case care.ActionTypes.SPOTLIGHT_CONTACTS_SEARCH_ERROR: {
      const payload = action.payload;

      return {
        ...state,
        allCustomerIds: [],
        count: 0,
        customers: [],
        spotlightContactsError: payload,
        spotlightContactsLoading: false,
        spotlightContactsNumberOfPages: 0
      };
    }

    case care.ActionTypes.UPDATE_ACTION_HISTORY_NOTE_SUCCESS: {
      const note = action.payload;
      let updatedSelectedReport = state.selectedReport;
      const recordIndex = findIndex(propEq('activityId', note.activityId))(state.selectedReport.activityHistory);
      const noteIndex = findIndex(propEq('noteId', note.noteId))(state.selectedReport.activityHistory[recordIndex].recoveryNotes);
      updatedSelectedReport.activityHistory[recordIndex].recoveryNotes[noteIndex] = note;

      return {
        ...state,
        selectedReport: {
          ...updatedSelectedReport,
          historyLoading: false,
          openActionHistoryNote: false
        }
      };
    }

    case care.ActionTypes.UPDATE_COPY: {
      const copy = action.payload;

      return { ...state, copy };
    }

    case care.ActionTypes.UPDATE_STATUS_ERROR: {
      const error = action.payload;

      return {
        ...state,
        customerNotesLoading: false,
        error,
        loading: false
      };
    }

    case care.ActionTypes.UPDATE_THEME: {
      const theme = action.payload;

      return {
        ...state,
        copy: theme ? theme.emailCopy : '',
        headline: theme ? theme.headLine : '',
        preHeader: theme ? theme.preHeader : '',
        subject: theme ? theme.subject : '',
        themeName: theme ? theme.themeName : '',
        themeUrl: theme ? theme.themeUrl : ''
      };
    }

    case care.ActionTypes.VIEW_REPORT_FROM_MEET: {
      return initialState;
    }

    case layout.ActionTypes.CHANGED_VIEW_SIZE: {
      const screenSize = action.payload;
      const pageSize = screenSize === 'xs' ? CareConstants.defaultSmallPageSize : CareConstants.defaultPageSize;

      return { ...state, pageSize };
    }

    default: {
      return state;
    }
  }
}

export const getAllCustomerIds = (state: State) => state.allCustomerIds;

export const getCareCustomerNotes = (state: State) => state.customerNotes;

export const getCareCustomerNotesError = (state: State) => state.customerNotesError;

export const getCareCustomerNotesLoading = (state: State) => state.customerNotesLoading;

export const getCareCustomerNotesToDisplay = (state: State) => state.customerNotesToDisplay;

export const getCareSelectedTabIndex = (state: State) => state.careSelectedTabIndex;

export const getCareSelectedView = (state:State) => state.careSelectedView;

export const getCfaOneSelectedView = (state:State) => state.cfaOneSelectedView;

export const getClosedCareContacts = (state: State) => state.closedCareContacts;

export const getClosedCareContactsError = (state: State) => state.closedCareContactsError;

export const getClosedCareContactsLoading = (state: State) => state.closedCareContactsLoading;

export const getClosedCareContactsNumberOfPages = (state: State) => state.closedCareContactsNumberOfPages;

export const getClosedCareContactsPage = (state: State) => state.closedCareContactsPage;

export const getClosedCareContactsPageSize = (state: State) => state.closedCareContactsPageSize;

export const getClosedCareContactsSearchBy = (state: State) => state.closedCareContactsSearchBy;

export const getClosedCareContactsSearchTerm = (state: State) => state.closedCareContactsSearchTerm;

export const getClosedCareContactsSortBy = (state: State) => state.closedCareContactsSortBy;

export const getClosedCareContactsSortOrder = (state: State) => state.closedCareContactsSortOrder;

export const getClosedCareContactsTotalElements = (state: State) => state.closedCareContactsTotalElements;

export const getClosedCareSelectedView = (state:State) => state.closedCareSelectedView;

export const getCopy = (state: State) => state.copy;

export const getDateSent = (state: State) => state.dateSent;

export const getDefaultCopy = (state: State) => state.defaultCopy;

export const getDefaultThemeName = (state: State) => state.defaultThemeName;

export const getError = (state: State) => state.error;

export const getHeadline = (state: State) => state.headline;

export const getIncludeReward = (state: State) => state.includeReward;

export const getLoading = (state: State) => state.loading;

export const getLoadRecoveredCfaOneContacts = (state: State) => state.shouldLoadRecoveredCfaOneContacts;

export const getMemberType = (state: State) => state.memberType;

export const getNumberOfPages = (state: State) => state.numberOfPages;

export const getOpeningStatement = (state: State) => state.openingStatement;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getPreHeader = (state: State) => state.preHeader;

export const getRecoveredCfaOneContacts = (state: State) => state.recoveredCfaOneContacts;

export const getRecoveredCfaOneContactsCurrentPage = (state: State) => state.recoveredCfaOneContactsCurrentPage;

export const getRecoveredCfaOneContactsDisplayErrorText = (state: State) => state.recoveredCfaOneContactsDisplayErrorText;

export const getRecoveredCfaOneContactsError = (state: State) => state.recoveredCfaOneContactsError;

export const getRecoveredCfaOneContactsLoading = (state: State) => state.recoveredCfaOneContactsLoading;

export const getRecoveredCfaOneContactsNumberOfPages = (state: State) => state.recoveredCfaOneContactsNumberOfPages;

export const getRecoveredCfaOneContactsPageSize = (state: State) => state.recoveredCfaOneContactsPageSize;

export const getRecoveredCfaOneContactsSearchBy = (state: State) => state.recoveredCfaOneContactsSearchBy;

export const getRecoveredCfaOneContactsSearchTerm = (state: State) => state.recoveredCfaOneContactsSearchTerm;

export const getRecoveredCfaOneContactsSortBy = (state: State) => state.recoveredCfaOneContactsSortBy;

export const getRecoveredCfaOneContactsSortOrder = (state: State) => state.recoveredCfaOneContactsSortOrder;

export const getRecoveredCfaOneContactsTotalElements = (state: State) => state.totalNumberOfRecoveredCfaOneContacts;

export const getRecoveredSelectedView = (state:State) => state.recoveredSelectedView;

export const getRecoverEligibilityLoading = (state: State) => state.recoverEligibilityLoading;

export const getReports = (state: State) => state.reports;

export const getRewardCategories = (state: State) => state.rewardCategories;

export const getSearchBy = (state: State) => state.searchBy;

export const getSearchTerm = (state: State) => state.searchTerm;

export const getSelectedCustomer = (state: State) => state.selectedCustomer;

export const getSelectedReport = (state: State) => state.selectedReport;

export const getSelectedRewards = (state: State) => state.selectedRewards;

export const getServiceNowCase = (state: State) => state.serviceNowCase;

export const getServiceNowCaseError = (state: State) => state.serviceNowCaseError;

export const getServiceNowCaseLoading = (state: State) => state.serviceNowCaseLoading;

export const getSortBy = (state: State) => state.sortBy;

export const getSortOrder = (state: State) => state.sortOrder;

export const getSpotlightContactsCustomers = (state: State) => state.customers;

export const getSpotlightContactsDisplayErrorText = (state: State) => state.spotlightContactsDisplayErrorText;

export const getSpotlightContactsError = (state: State) => state.spotlightContactsError;

export const getSpotlightContactsLoading = (state: State) => state.spotlightContactsLoading;

export const getSpotlightContactsNumberOfPages = (state: State) => state.spotlightContactsNumberOfPages;

export const getSpotlightContactsPage = (state: State) => state.spotlightContactsPage;

export const getSpotlightContactsPageLimit = (state: State) => state.limit;

export const getSpotlightContactsSearchBy = (state: State) => state.spotlightContactsSearchBy;

export const getSpotlightContactsSearchTerm = (state: State) => state.spotlightContactsSearchTerm;

export const getSpotlightContactsSortBy = (state: State) => state.spotlightContactsSortBy;

export const getSpotlightContactsSortOrder = (state: State) => state.spotlightContactsSortOrder;

export const getSpotlightContactsTotalCountOfCustomers = (state: State) => state.count;

export const getStatusOptions = (state: State) => state.statusOptions;

export const getSubject = (state: State) => state.subject;

export const getTemplateError = (state: State) => state.templateError;

export const getTemplateHtml = (state: State) => state.templateHtml;

export const getTemplateLoading = (state: State) => state.templateLoading;

export const getThemeName = (state: State) => state.themeName;

export const getThemeUrl = (state: State) => state.themeUrl;

export const getThemes = (state: State) => state.emailThemes;

export const getTotalNumberOfReports = (state: State) => state.totalNumberOfReports;
