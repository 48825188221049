import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment-timezone';
import { FormControl } from '@angular/forms';
import { clone } from 'ramda';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangePickerComponent implements OnInit {
  @Input()
  set startDate(startDate: moment.Moment) {
    if (startDate) {
      this.start = startDate;
      if (!this.allDaysDisabled) {
        this.setEndDateRestrictions(this.start);
        this.setAbsoluteMaxDate();
      }
    }
  }

  @Input()
  set maxDaysAllowed(value: number) {
    if (value) {
      this.maxDays = value;
      this.setAbsoluteMaxDate();
    }
  }

  @Input() public campaignType: string;
  @Input() public isMobile: boolean = false;
  @Input() public endDate: moment.Moment;
  @Input() public stopDate: number;
  @Input() public minDate: moment.Moment;
  @Input() public endDateRestriction: moment.Moment;
  @Input() public disabled: boolean = false;
  @Input() public disabledDays: string[];
  @Input() public allDaysDisabled: boolean = false;
  @Input() public futureDatesOnly: boolean = false;
  @Input() public pastDatesOnly: boolean = false;
  @Input() public disableSundays: boolean = false;
  @Input() public isStartDateRestriction: boolean = true;
  @Input() public isMaxDayRestrictionDynamic: boolean = false;
  @Input() public startDatePlaceholder: string = 'Start Date';
  @Input() public endDatePlaceholder: string = 'End Date';
  @Input() public startDateFormControl: FormControl;
  @Input() public endDateFormControl: FormControl;

  @Output() public datesChanged = new EventEmitter();

  public start: moment.Moment = null;
  public maxStart: moment.Moment = null;
  public maxEnd: moment.Moment = null;
  public minStart: moment.Moment = null;
  public minEnd: moment.Moment = null;
  public absoluteMinDate: moment.Moment = null;
  public absoluteMaxDate: moment.Moment = null;
  public maxDays: number;

  public ngOnInit() {
    if (this.futureDatesOnly) {
      this.absoluteMinDate = moment();
    } else {
      if (this.maxDays) {
        this.absoluteMinDate = moment().add(-(this.maxDays), 'days');
      } else if (this.minDate) {
        this.absoluteMinDate = this.minDate;
      }
    }
    this.minStart = this.absoluteMinDate;

    if (this.pastDatesOnly) {
      this.absoluteMaxDate = moment();
    } else {
      if (this.maxDays) {
        this.setAbsoluteMaxDate();
      } else if (this.endDateRestriction) {
        this.absoluteMaxDate = this.endDateRestriction;
      }
    }

    if (!this.allDaysDisabled) {
      this.setEndDateRestrictions(this.start);
      this.setStartDateRestrictions();
    }
  }

  public setStartDateRestrictions() {
    if (this.isStartDateRestriction) {
      this.maxStart = this.endDate ? this.endDate : this.absoluteMaxDate;
    }
    this.maxStart = this.absoluteMaxDate ? this.absoluteMaxDate : this.maxStart;
  }

  public setAbsoluteMaxDate() {
    // First, check if an end date restriction is set in Dynamo (passed in as endDateRestriction)
    if (this.endDateRestriction) {
      this.absoluteMaxDate = this.endDateRestriction;
      this.maxStart = this.endDateRestriction;

    // If not, then use the generic 365 day max (passed in as maxDays)
    } else {
      if (this.isMaxDayRestrictionDynamic) {
        this.absoluteMaxDate = (this.start).add(this.maxDays, 'days');
      } else if (this.maxDays) {
        this.absoluteMaxDate = moment().add(this.maxDays, 'days');
      }
    }
    if (!this.disabledDays){
      this.maxEnd = this.absoluteMaxDate;
    }
    this.endDate = this.endDate && (this.endDate).isAfter((this.maxEnd)) ? this.maxEnd : this.endDate;
  }

  public setEndDateRestrictions(startDate) {
    this.maxEnd = this.absoluteMaxDate ? this.absoluteMaxDate : this.maxEnd;
    this.minEnd = this.start ? this.start : this.absoluteMinDate;
   //if there are disabled days check if endDate should be restricted
    if (startDate && this.disabledDays) {
      this.disabledDays.forEach(dayString => {
        let day = moment(dayString);
        if (day.isAfter(startDate.format("YYYY-MM-DD")) && (day.isBefore(this.maxEnd.format("YYYY-MM-DD")))) {
          this.maxEnd = clone(day).add(-1,'days');
          //check for sundays
          if (this.disableSundays && this.maxEnd.weekday() === 0) {
            this.maxEnd = this.maxEnd.add(-1,'days');
          }
        }
      })
      this.endDate = this.endDate.isAfter(this.maxEnd) ? this.maxEnd : this.endDate;
    }
  }

  public endDateChanged(endDate) {
    let startDate = this.start;
    let dateObj = {
      endDate,
      startDate
    };
    this.datesChanged.emit(dateObj);
    this.setStartDateRestrictions();
  }

  public startDateChanged(startDate) {
    let dateObj = {
      startDate,
      endDate: this.endDate
    };
    this.datesChanged.emit(dateObj);
    this.setEndDateRestrictions(this.start);
  }
}
