import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import Bugsnag from '@bugsnag/js';

import { UserLocation } from '../../models/user/location.model';
import { AnalyticsService } from '../analytics';
import { clone, find, propEq } from 'ramda';
import * as fromRoot from '../../reducers';
import * as user from '../../actions/user';
import { ErrorHandlerService } from '../error-handler';
import { environment } from 'spotlight-ui/environments/environment';
import { EulaFoundAction } from '../../actions/eula';
import { AppConstants } from '../../app.constants';

@Injectable()
export class UserService {
  public selectedLocation$: Observable<UserLocation>;
  private selectedLocationSource: ReplaySubject<UserLocation>;
  private userData: any;
  private selectedLocation: UserLocation;

  public setupUser() {
    return new Promise((resolve) => {
      this.http.get(environment.spotlightApiUrl + '/api/user').pipe(
        catchError((error: any) => {
          Bugsnag.notify('Error Fetching User Data')
          //only place error action dispatched before the error handling service can handle, so do message logic here
          let message = error.error ?
            error.error.message ? error.error.message : error.error :
            error.message ? error.message : AppConstants.genericApiError
          this.store.dispatch(new user.UserDataErrorAction(message));
          resolve(error)
          return error;
        })
      ).subscribe((response: any) => {
        let userData = response.responseObject;
        this.analytics.setup(userData);
        this.store.dispatch(new user.UserDataFoundAction(clone(userData)));
        this.selectDefaultLocation(userData);
        this.userData = userData;
        resolve(true);
      });
    });
  }

  public getEula(): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(environment.spotlightApiUrl + '/api/eula/accept').pipe(
        catchError((error: any) => {
          resolve(error);
          return this.ehs.handleError(error);
        }))
        .subscribe((eulaData: any) => {
          this.store.dispatch(new EulaFoundAction(eulaData.responseObject));
          resolve(true);
        });
    });
  }

  constructor(private http: HttpClient,
              private store: Store<fromRoot.State>,
              private ehs: ErrorHandlerService,
              private injector: Injector,
              private analytics: AnalyticsService) {
    this.selectedLocationSource = new ReplaySubject(1);
    this.selectedLocation$ = this.selectedLocationSource.asObservable();
  }

  public getSelectedLocation(): Observable<any> {
    if (this.selectedLocation) {
      return of(this.selectedLocation);
    } else {
      return this.getUser().pipe(map(() => this.selectedLocation));
    }
  }

  public updateSelectedLocation(location) {
    this.analytics.setLocationAttributes(location);
    this.selectedLocation = location;
    this.store.select(fromRoot.getFeatureEnabled(AppConstants.maintenanceFlag)).pipe(take(1)).subscribe(activeMaintenance => {
      if (!window.location.href.includes('/help') && !window.location.href.includes('/eula')
        && !activeMaintenance) {
        this.store.dispatch(new user.SelectLocation(location));
      }
      this.selectedLocationSource.next(location);
    });
  }

  public dismissAlert(alertId) {
    return this.getSelectedLocation().pipe(
      switchMap((loc) => {
        const url = `/api/location/${loc.locationNumber}/user/alert/${alertId}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      })
    );
  }

  public updateBannerSettings(bannerId) {
    return this.getUser().pipe(
      switchMap((user) => {
        const url = `/api/user/banner/${bannerId}/${user.userName}`;
        return this.http.delete(environment.spotlightApiUrl + url);
      }),
      map((response: any) => {
        return bannerId;
      }),
      catchError((error: any) => {
        Bugsnag.notify(new Error('Update Banner Failed'));
        return this.ehs.handleError(error);
      })
    );
  }

  public getUser(): Observable<any> {
    return of(this.userData);
  }

  public getLocationDetails(location, isAdminFull, isAdminView) {
    if (isAdminFull || isAdminView) {
      return this.http.get(environment.spotlightApiUrl + `/api/location/${location.locationNumber}`).pipe(
        map((res: any) =>{ return res}),
        catchError((err: any) => {
          Bugsnag.notify(new Error('Select Location Failed'));
          return this.ehs.handleError(err);
        })
      );
    } else {
      return of(location);
    }
  }

  public getDiscoverButtons(): Observable<any> {
    return this.http.get(environment.spotlightApiUrl + '/api/discover/discoverButtons').pipe(
      map((res:any) => res),
      catchError((err:any) => this.ehs.handleError(err))
    );
  }

  private selectDefaultLocation(userData) {
    let defaultLocation = '';
    if (localStorage.getItem('selectedLocation') !== null) {
      defaultLocation = find(
        propEq('locationNumber', localStorage.getItem('selectedLocation'))
      )(userData.locations);
      this.updateSelectedLocation(defaultLocation);
    } else {
      this.updateSelectedLocation(userData.locations[0]);
    }
  }
}
