<div class="edit-third-party-promotion-details__form">
  <template-summary [templateName]="tile.descriptionText.secondary"
                    [imageURL]="tile.displayImageUrl"
                    [details]="tile.descriptionText.suggestedUseText"
                    [isMobile]="isMobile"
                    [isDisabled]="true"
                    [isRoundedBorder]="true">
  </template-summary>

  <div fxLayout="column" fxLayoutAlign="center start" class="edit-third-party-promotion-details__section-container">
    <div class="edit-third-party-promotion-details__header">Free Menu Item Selection</div>
    <ng-container>
      <template-summary class="edit-third-party-promotion-details__reward-summary"
                        [templateName]="selectedReward?.name"
                        [imageURL]="selectedReward?.offerImage"
                        [isMobile]="isMobile"
                        [isDisabled]="true">
      </template-summary>
      <mat-select class="edit-third-party-promotion-details__reward-dropdown"
                  [disabled]="isReview"
                  [ngModel]="selectedReward"
                  (selectionChange)="updateReward($event.value)">
        <div class="edit-third-party-promotion-details__reward-category">Select the Reward you'd like to send
          <img class="edit-third-party-promotion-details__chevron"
               src="https://www.cfacdn.com/img/Spotlight/Icons/NavyChevronRounded_Icon.png" alt="">
        </div>
        <div *ngFor="let category of rewardCategories">
          <div *ngIf="rewardList.length > 6" class="edit-third-party-promotion-details__reward-category">{{category.category}}</div>
          <mat-option *ngFor="let reward of category.rewards" [value]="reward" class="edit-third-party-promotion-details__reward-option">
            <div matLine fxLayout="row" fxLayoutAlign="start center">
              <div class="edit-third-party-promotion-details__reward-width">
                <img fxflex class="edit-third-party-promotion-details__reward-image" src="{{reward.offerImage}}" alt="">
              </div>
              <span fxFlex class="edit-third-party-promotion-details__reward-title">{{reward.name}}</span>
            </div>
          </mat-option>
        </div>
      </mat-select>
    </ng-container>
  </div>

  <ng-container>
    <div class="edit-third-party-promotion-details__header"
         [ngClass]="{'edit-third-party-promotion-details__error-missing-field-header': (campaignNameFormControl && campaignNameFormControl.invalid)}">
      Internal Campaign Name
    </div>
    <mat-form-field appearance="none" class="edit-third-party-promotion-details__name-input">
      <input matInput
             maxlength="75"
             [formControl]="campaignNameFormControl"
             [readonly]="isReview"
             [value]="campaignName"
             (keyup)="onCampaignNameChange($event)">
      <mat-error>
        Internal Campaign Name is required
      </mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container>
    <div fxLayout="column" class="edit-third-party-promotion-details__section-container">
      <span class="edit-third-party-promotion-details__header">Activate promotion on</span>
      <div fxLayout="column" fxLayoutAlign="space-around start">
        <div *ngFor="let platform of platforms" fxLayout="row">
          <mat-checkbox [checked]="platform.selected"
                        [disabled]="isReview"
                        (change)="platformChanged(platform)">
          </mat-checkbox>
          <span class="edit-third-party-promotion-details__text"> {{platform.name}}</span>
        </div>
        <mat-error *ngIf="submitted && getNoPlatformSelected()" class="edit-third-party-promotion-details__error-text">
          Please select third party platform.
        </mat-error>
      </div>
    </div>
    <div *ngIf="allDaysDisabled" class="edit-third-party-promotion-details__all-days-disabled-text">
      A promotion for this third party platform cannot be scheduled due to overlapping promotions for this third party partner.
    </div>
  </ng-container>
  <ng-container>
    <div fxLayout="column" class="edit-third-party-promotion-details__section-container">
      <span class="edit-third-party-promotion-details__header">Customer Types</span>
      <span class="edit-third-party-promotion-details__text">
        Refine who will qualify for the promotion by targeting specific Third Party customers.
      </span>
      <div fxLayout="column" fxLayoutAlign="space-between start">
        <div *ngFor="let customerType of customerTypes" fxLayout="row">
          <mat-slide-toggle [checked]="customerType.selected"
                            [disabled]="isReview"
                            (change)="customerTypeUpdated(customerType)">
          </mat-slide-toggle>
          <span class="edit-third-party-promotion-details__bold-text">{{" " + customerType.displayTitle + " "}}</span>
          <span class="edit-third-party-promotion-details__text">{{customerType.subTitle + " "}}</span>
          <help-text *ngIf="customerType.info"
                     [helpText]="customerType.info"
                     [displayOnLeft]="true"
                     [blockDisplay]="true"
                     [isSmallIcon]="isMobile">
          </help-text>
        </div>
        <mat-error *ngIf="submitted && getNoCustomerTypeSelected()" class="edit-third-party-promotion-details__error-text">
          Please select customer type.
        </mat-error>
      </div>
    </div>
  </ng-container>
  <div class="edit-third-party-promotion-details__date-selection">
    <date-range-picker [disabled]="isReview || allDaysDisabled"
                       [disabledDays]="disabledDays"
                       [disableSundays]="true"
                       [allDaysDisabled]="allDaysDisabled"
                       [endDate]="endDate"
                       [endDatePlaceholder]="'End on'"
                       [endDateRestriction]="endDateRestriction"
                       [futureDatesOnly]="true"
                       [isMaxDayRestrictionDynamic]="false"
                       [isMobile]="isMobile"
                       [isStartDateRestriction]="true"
                       [startDate]="startDate"
                       [startDatePlaceholder]="'Start on'"
                       [stopDate]="stopDate"
                       (datesChanged)="updateDate($event)">
    </date-range-picker>
  </div>
  <ng-container>
    <div class="edit-third-party-promotion-details__section-container">
      <span class="edit-third-party-promotion-details__bold-text">Note: </span>
      <span class="edit-third-party-promotion-details__text">This promotion is enabled for Third Party Platforms only. Eligible
        customers must have a minimum order of $12 or more, before applicable taxes and fees, and for DoorDash including value of the
        free item; for Uber excluding value of the free item. Limit one redemption per person, per promotion. Subject to availability.
      </span>
    </div>
  </ng-container>
  <ng-container>
    <div *ngIf = "enableTermsAndConditions" fxLayout="column" fxLayoutAlign="space-between start" class="edit-third-party-promotion-details__section-container">
      <div *ngFor="let platform of platforms">
        <div *ngIf="platform.selected" fxLayout="row">
          <mat-checkbox *ngIf="platform.selected"
                        [checked]="platform.termsAndConditionsAccepted || isReview"
                        [disabled]="isReview"
                        (change)="promotionTermsAndConditionsUpdated(platform)">
          </mat-checkbox>
          <span class="edit-third-party-promotion-details__text"> I agree with {{platform.name}}'s </span>
          <help-text class="edit-third-party-promotion-details__red-text"
                     [ngClass]="{'edit-third-party-promotion-details__in-review' : isReview}"
                     [blockDisplay]= "true"
                     [displayInCenter]="true"
                     [helpText]="platform.termsAndConditions"
                     [hoverText]="termsText">
          </help-text>
          </div>
        </div>
      </div>
  </ng-container>
  <ng-container>
    <div class="edit-third-party-promotion-details__section-container">
      <span class="edit-third-party-promotion-details__italic-text">
        Chick-fil-A, Inc. does not administer this promotion. Please note that you are solely responsible for the food & paper costs
        associated with the creation and activation of this promotion.
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="isReview && !statsLoading && stats">
    <mat-nav-list *ngFor="let stat of stats; let i = index" [ngClass]="isMobile ? 'edit-third-party-promotion-details__list-mobile' : 'edit-third-party-promotion-details__list'">
      <h4 *ngIf="stats.length > 1" class="edit-third-party-promotion-details__analytics-title">
        Analytics Summary: {{stat.platform}}
      </h4>
      <h4 *ngIf="stats.length == 1" class="edit-third-party-promotion-details__analytics-title">
        Analytics Summary
      </h4>
      <div class="edit-third-party-promotion-details__grid">
        <div fxLayout="row" class="edit-third-party-promotion-details__analytics-label">Total Transactions<help-text class="edit-third-party-promotion-details__help-text" [helpText]="totalTransHelp" [is3pSize]="true"></help-text>:</div>
        <div class="edit-third-party-promotion-details__analytics-data">{{stat.totalTransactions}}</div>
        <div fxLayout="row" class="edit-third-party-promotion-details__analytics-label">Total Sales<help-text class="edit-third-party-promotion-details__help-text" [helpText]="totalSalesHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:</div>
        <div class="edit-third-party-promotion-details__analytics-data">${{stat.totalSales | number : '1.2-2'}}</div>
        <div fxLayout="row" class="edit-third-party-promotion-details__analytics-label">Promotional Transactions<help-text class="edit-third-party-promotion-details__help-text" [helpText]="promotionalTransHelp" [is3pSize]="true"></help-text>:</div>
        <div class="edit-third-party-promotion-details__analytics-data">{{stat.promotionalTransactions}}</div>
        <div fxLayout="row" class="edit-third-party-promotion-details__analytics-label">Promotional Sales<help-text class="edit-third-party-promotion-details__help-text" [helpText]="promotionalSalesHelp" [displayOnLeft]="true" [is3pSize]="true"></help-text>:</div>
        <div class="edit-third-party-promotion-details__analytics-data">${{stat.promotionalSales | number : '1.2-2'}}</div>
        <div></div><div></div>
      </div>
    </mat-nav-list>
  </ng-container>
  <ng-container *ngIf="isReview && !statsLoading && !stats">
    <div class ="edit-third-party-promotion-details__stats-error">
      <mat-icon class="edit-third-party-promotion-details__report-icon">report</mat-icon>
      <p class="edit-third-party-promotion-details__stats-error-text"> We’re having issues loading the analytics summary. Try again later.</p>
    </div>
  </ng-container>

  <button *ngIf="!isMobile && !isReview"
          [disabled] ="disableSubmit() || allDaysDisabled"
          mat-raised-button
          class="edit-third-party-promotion-details__button"
          id="edit-third-party-promotion-details__create-promotion-button"
          (click)="goToConfirmation()">
    Create Promotion
  </button>
  <button *ngIf="isMobile && !isReview"
          [disabled] ="disableSubmit()"
          mat-raised-button
          class="edit-third-party-promotion-details__button"
          id="edit-third-party-promotion-details__mobile-preview-button"
          (click)="goToPreview()">
    Preview
  </button>
  <div fxLayout="row" fxLayoutAlign="space-around start">
    <button *ngIf="isReview" mat-raised-button
            class="edit-third-party-promotion-details__cancel-button"
            id="edit-third-party-promotion-details__stop-promotion-button"
            [disabled]="hasPromotionEnded()"
            (click)="goToStopPromotion()">
      Stop Promotion
    </button>
    <button *ngIf="isReview" mat-raised-button
            class="edit-third-party-promotion-details__button"
            id="edit-third-party-promotion-details__go-to-calendar-button"
            (click)="goToCalendar()">
      Go To Calendar
    </button>
  </div>
</div>
