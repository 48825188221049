import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'spotlight-ui/environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleAccess(request, next);
  }

  private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add an access token to allowed origins
    const allowedOrigins = environment.urlsForAuthHeader;
    if (allowedOrigins.some(url => request.urlWithParams.includes(url))) {
      let token = this.oktaAuth.getAccessToken();
      const tokenExpTime = JSON.parse(localStorage.getItem('okta-token-storage') ?? '{}',).accessToken?.expiresAt;
      const currTime = Math.floor(Date.now() / 1000);
      if (!token || (token && tokenExpTime < currTime)) {
        this.oktaAuth.signInWithRedirect().then();
        return of(new HttpResponse());
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          }
        });
      }
    }
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.status === 200) {
          // If you want to take an action on a success response, do it here.
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const allowedOrigins = environment.urlsForAuthHeader;
        if (allowedOrigins.some(url => request.urlWithParams.includes(url))) {
          if (error.status === 403) {
            this.oktaAuth.setOriginalUri(window.location.href);
          }
          return throwError(error);
        }
      })
    )
  }
}
