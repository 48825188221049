import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AnalyticsService } from 'spotlight-ui/app/core/analytics';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CareConstants } from '../care.constants';
import { ServiceNowCase } from '../../models/recover/ServiceNowCase';

@Component({
  selector: 'service-now-report',
  templateUrl: './service-now-report.component.html',
  styleUrls: ['./service-now-report.component.scss']
})
export class ServiceNowReportComponent {
  public isMobile$: Observable<boolean>;
  public serviceNowCase$: Observable<ServiceNowCase>;
  public serviceNowCaseError$: Observable<string>;
  public serviceNowCaseLoading$: Observable<boolean>;

  public reassignReportDialogText: string = CareConstants.reassignReportDialogText;
  public acknowledgeReportDialogText: string = CareConstants.acknowledgeReportDialogText;
  public highPriority: string = CareConstants.highPriority;

  constructor(private analytics: AnalyticsService,
              private dialog: MatDialog,
              private store: Store<fromRoot.State>,
              private router: Router,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              public dialogRef: MatDialogRef<ServiceNowReportComponent>) {
    this.isMobile$ = this.store.select(fromRoot.getIsMobile);
    this.serviceNowCase$ = this.store.select(fromRoot.getCareServiceNowCase);
    this.serviceNowCaseError$ = this.store.select(fromRoot.getCareServiceNowCaseError);
    this.serviceNowCaseLoading$ = this.store.select(fromRoot.getCareServiceNowCaseLoading);
  }

  public closeReport() {
    this.dialogRef.close();
  }

  public acknowledgeReport() {
    this.serviceNowCase$.pipe(take(1)).subscribe((serviceNowCase) => {
      this.analytics.sendMParticleEvent(
        'Report Acknowledged',
        { reportNumber: serviceNowCase.reportNumber }
      );
    });
    let config = new MatDialogConfig();
    config.data = {
      confirmText: 'Back',
      displayText: this.acknowledgeReportDialogText,
      hideCancel: true
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.closeReport();
        this.router.navigate(['/care']);
      }
    });
  }

  public reassignReport() {
    this.serviceNowCase$.pipe(take(1)).subscribe((serviceNowCase) => {
      this.analytics.sendMParticleEvent(
        'Report Reassigned',
        { reportNumber: serviceNowCase.reportNumber }
      );
    });
    let config = new MatDialogConfig();
    config.data = {
      confirmText: 'Back',
      displayText: this.reassignReportDialogText,
      hideCancel: true
    };

    this.dialog.open(ConfirmationDialogComponent, config).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.closeReport();
        this.router.navigate(['/care']);
      }
    });
  }
}
