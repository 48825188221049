import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../core/error-handler';
import { UserService } from '../core/user';
import { UserLocation } from '../models/user/location.model';
import { environment } from '../../environments/environment';

@Injectable()
export class HelpService {
  constructor(
    private us: UserService,
    private http: HttpClient,
    private ehs: ErrorHandlerService
  ) {}

  // Hard coding id for now since we only have one help page currently
  public loadHelpContent() {
    let id = 'additional-resources';
    return this.us.getSelectedLocation().pipe(
      switchMap((loc: UserLocation) => {
        const locationNumber = loc.locationNumber;
        const url = `/api/location/${locationNumber}/help/${id}`;
        return this.http.get(environment.spotlightApiUrl + url);
      }),
      map((res) => res || {}),
      catchError((err) => this.ehs.handleError(err))
    );
  }
}
