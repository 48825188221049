import * as calendar from '../actions/calendar';
import * as customer from '../actions/customer';
import * as engage from '../actions/engage';
import * as meet from '../actions/meet';
import * as user from '../actions/user';
import { AppConstants } from '../app.constants';
import { MeetConstants } from '../meet/meet.constants';
import { SharedConstants } from '../shared/shared.constants';
import { Customer } from '../models/customer/customer.model';
import { Inactives } from '../models/shared/inactive-flag.model';
import { any, clone, contains, find, findIndex, propEq } from 'ramda';
import * as moment from 'moment-timezone';

export interface State {
  allFilters: any[];
  allSavedGroups: any[];
  autocomplete: string;
  autocompleteLoading: boolean;
  columns: any[];
  columnsWithFlag: any[];
  count: number;
  customers: Customer[];
  displayErrorText: boolean;
  engageDisplayFilters: any[];
  engageDisplaySavedGroups: any[];
  engageExcludedCustomers: any[];
  engageFiltersEndDate: any;
  engageFiltersStartDate: any;
  engageFiltersOnBetweenId: string;
  engageIncludedCustomers: any[];
  engageSelectedFilters: any[];
  engageSelectedSavedGroups: any[];
  error: string;
  filtersError?: string;
  filtersLoading: boolean;
  filtersWithoutSelectedSubOption: number;
  filtersWithoutSelectedToggle: number;
  inactiveFlag: Inactives;
  isCatering: boolean;
  isFeedback: boolean;
  isKidsClub: boolean;
  isSelectedSavedGroupLarge?: boolean;
  limit: number;
  meetDisplayFilters: any[];
  meetDisplaySavedGroups: any[];
  meetExcludedCustomers: any[];
  meetIncludedCustomers: any[];
  meetFiltersEndDate: any;
  meetFiltersOnBetweenId: string;
  meetFiltersStartDate: any;
  meetSavedGroupBeingEdited: any;
  meetSelectedFilters: any[];
  meetSelectedSavedGroups: any[];
  modifySavedGroupsLoading: boolean;
  page: number;
  savedGroupsError?: string;
  savedGroupsLoading: boolean;
  searchBy: string;
  searchTerm: string;
  selectedCustomer: any;
  selectedSavedGroupCustomers: any[];
  selectedSavedGroupCustomersError?: string;
  selectedSavedGroupCustomersLoading: boolean;
  selectedSavedGroupId?: string;
  sort: string;
  sortOrder: string;
  view: string;
}

export const initialState: State = {
  allFilters: [],
  allSavedGroups: [],
  autocomplete: '',
  autocompleteLoading: false,
  columns: [{
    hide: true,
    mobile: 'title',
    prop: 'fullName',
    title: 'Full Name'
  }, {
    prop: 'firstName',
    title: 'First Name'
  }, {
    prop: 'lastName',
    title: 'Last Name'
  }, {
    mobile: 'subtitle',
    mobileTitle: 'Last visited',
    prop: 'lastTransactionDate',
    title: 'Last Visit Date',
    type: 'visitDate'
  }, {
    prop: 'birthDate',
    title: 'Birthday'
  }, {
    prop: 'membership',
    sort: false,
    title: 'Member Status',
    type: 'membership'
  }],
  columnsWithFlag: [{
    hide: true,
    mobile: 'title',
    prop: 'fullName',
    title: 'Full Name'
  }, {
    prop: 'firstName',
    title: 'First Name'
  }, {
    prop: 'lastName',
    title: 'Last Name'
  }, {
    prop: 'refundRedFlagged',
    title: 'Flagged',
    type: 'redFlag'
  }, {
    mobile: 'subtitle',
    mobileTitle: 'Last visited ',
    prop: 'lastTransactionDate',
    title: 'Last Visit Date',
    type: 'visitDate'
  }, {
    prop: 'birthDate',
    title: 'Birthday'
  }, {
    prop: 'membership',
    sort: false,
    title: 'Member Status',
    type: 'membership'
  }],
  count: 0,
  customers: [],
  displayErrorText: false,
  engageDisplayFilters: [],
  engageDisplaySavedGroups: [],
  engageExcludedCustomers: [],
  engageFiltersEndDate: null,
  engageFiltersOnBetweenId: SharedConstants.onBetweenDefaultId,
  engageFiltersStartDate: null,
  engageIncludedCustomers: [],
  engageSelectedFilters: [],
  engageSelectedSavedGroups: [],
  error: null,
  filtersLoading: true,
  filtersWithoutSelectedSubOption: 0,
  filtersWithoutSelectedToggle: 0,
  inactiveFlag: Inactives.EXCLUDE_INACTIVES,
  isCatering: false,
  isFeedback: false,
  isKidsClub: false,
  limit: 20,
  meetDisplayFilters: [],
  meetDisplaySavedGroups: [],
  meetExcludedCustomers: [],
  meetFiltersEndDate: null,
  meetFiltersOnBetweenId: SharedConstants.onBetweenDefaultId,
  meetFiltersStartDate: null,
  meetIncludedCustomers: [],
  meetSavedGroupBeingEdited: null,
  meetSelectedFilters: [],
  meetSelectedSavedGroups: [],
  modifySavedGroupsLoading: false,
  page: 0,
  savedGroupsLoading: true,
  searchBy: 'customerName',
  searchTerm: '',
  selectedCustomer: null,
  selectedSavedGroupCustomers: [],
  selectedSavedGroupCustomersLoading: true,
  sort: 'firstName',
  sortOrder: 'asc',
  view: 'All Customers',
};

export function getFormattedDisplayFilters(selectedFilters, displayFilters, onBetweenId, startDate, endDate) {
  selectedFilters.map(selectedFilter => {
    const selectedCategory = find(propEq('id', selectedFilter.parentCategory))(displayFilters);
    const subFilters = selectedCategory.subFilters;
    let selectedSubFilter = find(propEq('id', selectedFilter.id))(subFilters);
    if (selectedFilter.selectedButtonToggle) {
      selectedSubFilter.buttonToggles.map(buttonToggle => {
        buttonToggle.selected = selectedFilter.selectedButtonToggle === buttonToggle.id;
      });
    }

    if (selectedFilter.subOptions) {
      selectedSubFilter.subOptions = selectedFilter.subOptions;
    }

    if (selectedFilter.selectedButtonChipSections) {
      selectedSubFilter.buttonChipSections.map(buttonChipSection => {
        let selectedButtonChipSection = find(propEq('id', buttonChipSection.id))(selectedFilter.selectedButtonChipSections);
        if (selectedButtonChipSection) {
          buttonChipSection.buttonChips.map(chip => {
            chip.selected = contains(chip.id, selectedButtonChipSection.selectedButtonChips);
          });
        } else {
          buttonChipSection.buttonChips.map((chip) => chip.selected = false);
        }
      });
    }

    // Works for both single and double sliders since highValue will just be null, as desired
    if (selectedFilter.selectedSliderValue !== null && selectedFilter.selectedSliderValue !== undefined) {
      selectedSubFilter.sliderOptions = {
        ...selectedSubFilter.sliderOptions,
        value: selectedFilter.selectedSliderValue,
        highValue: selectedFilter.selectedSliderHighValue
      };
    }
  });

  displayFilters = setDateDisplayFilter(displayFilters, onBetweenId, startDate, endDate);

  return displayFilters;
}

// Remove individual customer filters and date filters while also returning the appropriate values to account for them on the state
export function separateExtraFilters(selectedFilters) {
  let formattedSelectedFilters = clone(selectedFilters);
  let engageIncludedCustomers = [];
  let engageExcludedCustomers = [];
  let engageFiltersOnBetweenId = null;
  let engageFiltersStartDate = null;
  let engageFiltersEndDate = null;

  if (formattedSelectedFilters) {
    let includeIndividualFilterIndex = findIndex(propEq('id', MeetConstants.includeFilterId))(formattedSelectedFilters);
    if (includeIndividualFilterIndex > -1) {
      engageIncludedCustomers = formattedSelectedFilters[includeIndividualFilterIndex].cfaIdList.map(id => {
        return { id, requiresCustomerDetails: true };
      });
      formattedSelectedFilters.splice(includeIndividualFilterIndex, 1);
    }

    let excludeIndividualFilterIndex = findIndex(propEq('id', MeetConstants.excludeFilterId))(formattedSelectedFilters)
    if (excludeIndividualFilterIndex > -1) {
      engageExcludedCustomers = formattedSelectedFilters[excludeIndividualFilterIndex].cfaIdList.map(id =>{
        return { id, requiresCustomerDetails: true };
      });
      formattedSelectedFilters.splice(excludeIndividualFilterIndex, 1);
    }

    let onBetweenFilterIndex = findIndex(propEq('id', SharedConstants.filtersOnBetweenId))(formattedSelectedFilters);
    if (onBetweenFilterIndex > -1) {
      engageFiltersOnBetweenId = formattedSelectedFilters[onBetweenFilterIndex].selectedOnBetweenId;
      formattedSelectedFilters.splice(onBetweenFilterIndex, 1);
    }

    let customDateSubFilterId = findIndex(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(formattedSelectedFilters);
    if (customDateSubFilterId > -1) {
      engageFiltersStartDate = formattedSelectedFilters[customDateSubFilterId].startDate;
      engageFiltersEndDate = formattedSelectedFilters[customDateSubFilterId].endDate;
      formattedSelectedFilters.splice(customDateSubFilterId, 1);
    }
  }

  return {
    formattedSelectedFilters,
    engageIncludedCustomers,
    engageExcludedCustomers,
    engageFiltersOnBetweenId,
    engageFiltersStartDate,
    engageFiltersEndDate
  };
}

export function setDateDisplayFilter(displayFilters, onBetweenId, startDate, endDate) {
  let formattedDisplayFilters = clone(displayFilters);

  if (displayFilters && displayFilters.length > 0) {
    let selectedCategory = find(propEq('id', SharedConstants.filtersDateRangeId))(formattedDisplayFilters);
    let subFilters = selectedCategory.subFilters;
    let onBetweenSubFilter = find(propEq('id', SharedConstants.filtersOnBetweenId))(subFilters);
    let selectedSliderOption = find(propEq('id', onBetweenId))(onBetweenSubFilter.sliderOptions.stepsArray);
    let customSubFilter = find(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(subFilters);

    if (onBetweenId && onBetweenId !== SharedConstants.onBetweenDefaultId) {
      // Set On/Between Date Display Filter
      onBetweenSubFilter.isSelected = true;
      customSubFilter.isSelected = false;
      onBetweenSubFilter.sliderOptions = {
        ...onBetweenSubFilter.sliderOptions,
        value: selectedSliderOption.value,
        highValue: null,
        title: selectedSliderOption.title
      };
    } else if (startDate || endDate) {
      // Set Custom Date Range Display Filter
      customSubFilter.isSelected = true;
      onBetweenSubFilter.isSelected = false;
    } else {
      // Set to defaults if neither exist
      onBetweenSubFilter.isSelected = true;
      customSubFilter.isSelected = false;
      onBetweenSubFilter.sliderOptions = {
        ...onBetweenSubFilter.sliderOptions,
        value: SharedConstants.onBetweenDefaultValue,
        title: SharedConstants.onBetweenDefaultTitle,
        highValue: null
      };
    }
  }

  return formattedDisplayFilters;
}

export function updateFiltersWithoutSelectedSubOption(selectedFilters) {
  let filtersWithoutSelectedSubOption = 0;

  if (selectedFilters) {
    selectedFilters.forEach((selectedFilter) => {
      if (selectedFilter && selectedFilter.subOptions) {
        // Check that the SubOptions has at least one selected
        for (let i = 0; i < selectedFilter.subOptions.length; i++) {
          if (selectedFilter.subOptions[i].selected === true) {
            // If one is selected then cancel searching
            i = selectedFilter.subOptions.length;
          } else if (i === selectedFilter.subOptions.length - 1) {
            // If it reaches the end without finding one then increase the count
            filtersWithoutSelectedSubOption++;
          }
        }
      }
    });
  }

  return filtersWithoutSelectedSubOption;
}

export function updateFiltersWithoutSelectedToggle(selectedFilters) {
  let filtersWithoutSelectedToggle = 0;

  if (selectedFilters) {
    selectedFilters.forEach((selectedFilter) => {
      if (selectedFilter && selectedFilter.buttonToggles) {
        // Check that the buttonToggles has one toggle selected
        for (let i = 0; i < selectedFilter.buttonToggles.length; i++) {
          if (selectedFilter.buttonToggles[i].selected === true) {
            // If one is selected then cancel searching
            i = selectedFilter.buttonToggles.length;
          } else if (i === selectedFilter.buttonToggles.length - 1) {
            // If it reaches the end without finding one then increase the count
            filtersWithoutSelectedToggle++;
          }
        }
      }
    });
  }

  return filtersWithoutSelectedToggle;
}

export function reducer(
  state = initialState,
  action: calendar.Actions | customer.Actions | engage.Actions | meet.Actions | user.Actions
): State {
  switch (action.type) {
    case calendar.ActionTypes.CALENDAR_SWITCH_LOCATIONS: {
      return {
        ...state,
        count: 0,
        customers: [],
        selectedCustomer: null
      };
    }

    case calendar.ActionTypes.FETCH_EMAIL_DETAILS_SUCCESS:
    case calendar.ActionTypes.FETCH_EVENT_DETAILS_SUCCESS: {
      const { selectedFilters, selectedSavedGroups } = clone(action.payload);
      const {
        formattedSelectedFilters,
        engageIncludedCustomers,
        engageExcludedCustomers,
        engageFiltersOnBetweenId,
        engageFiltersStartDate,
        engageFiltersEndDate
      } = separateExtraFilters(selectedFilters);

      return {
        ...state,
        engageExcludedCustomers,
        engageFiltersEndDate,
        engageFiltersOnBetweenId,
        engageFiltersStartDate,
        engageIncludedCustomers,
        engageSelectedFilters: formattedSelectedFilters ? formattedSelectedFilters : [],
        engageSelectedSavedGroups: selectedSavedGroups ? selectedSavedGroups : []
      };
    }

    case customer.ActionTypes.AUTOCOMPLETE_ERROR:
    case customer.ActionTypes.CUSTOMER_ERROR: {
      const error = action.payload;
      return {
        ...state,
        autocompleteLoading: false,
        count: 0,
        customers: [],
        error
      };
    }

    case customer.ActionTypes.AUTOCOMPLETE_FOUND: {
      const payload = action.payload;
      return {
        ...state,
        autocompleteLoading: false,
        count: payload && payload.customerProfiles ? payload.customerProfiles.length : 0,
        customers: payload && payload.customerProfiles ? payload.customerProfiles : [],
        error: null
      };
    }

    case customer.ActionTypes.AUTOCOMPLETE_SEARCH: {
      const autocomplete = action.payload;
      return {
        ...state,
        autocomplete,
        autocompleteLoading: true,
        error: null,
      };
    }

    case customer.ActionTypes.AUTOCOMPLETE_SELECTED_CUSTOMER: {
      const selectedCustomer = action.payload;
      return { ...state, selectedCustomer };
    }

    case customer.ActionTypes.CHANGE_PAGE: {
      const page = action.payload;
      return { ...state, page };
    }

    case customer.ActionTypes.CHANGE_PAGE_SIZE: {
      const limit = action.payload;
      return { ...state, limit, page: 0 };
    }

    case customer.ActionTypes.CHANGE_SORT: {
      const payload = action.payload;
      return { ...state, sort: payload.prop, sortOrder: payload.order };
    }

    case customer.ActionTypes.CHANGE_VIEW: {
      const payload = action.payload;
      const view = updateViewState(payload);
      const merge = {
        page: 0,
        sort: view.columns[0]['prop'],
        sortOrder: 'asc'
      };

      return { ...state, ...view, ...merge, view: payload };
    }

    case customer.ActionTypes.CLEAR_SEARCH: {
      return {
        ...state,
        displayErrorText: false,
        page: 0,
        searchTerm: ''
      };
    }

    case customer.ActionTypes.DISPLAY_ERROR_TEXT: {
      return { ...state, displayErrorText: action.payload };
    }

    case customer.ActionTypes.MAKE_ACTIVE_SUCCESS: {
      const view = updateViewState(MeetConstants.allCustomerView);
      const merge = {
        page: 0,
        sort: view.columns[0]['prop'],
        sortOrder: 'asc'
      };
      return { ...state, ...view, ...merge, view: MeetConstants.allCustomerView };
    }

    case customer.ActionTypes.MAKE_INACTIVE_SUCCESS: {
      const view = updateViewState(MeetConstants.inactivesView);
      const merge = {
        page: 0,
        sort: view.columns[0]['prop'],
        sortOrder: 'asc'
      };
      return { ...state, ...view, ...merge, view: MeetConstants.inactivesView };
    }

    case customer.ActionTypes.SEARCH: {
      return {
        ...state,
        displayErrorText: false,
        page: 0,
        searchTerm: action.payload
      };
    }

    case customer.ActionTypes.SEARCH_BY: {
      return {
        ...state,
        displayErrorText: false,
        searchBy: action.payload
      };
    }

    case engage.ActionTypes.FETCH_EMAIL_DRAFT_DETAILS_SUCCESS: {
      let { displayFilters, selectedFilters, selectedSavedGroups } = clone(action.payload);

      // If display filters are returned, that means that there was a previously selected filter or saved group
      if (displayFilters) {
        let {
          formattedSelectedFilters,
          engageIncludedCustomers,
          engageExcludedCustomers,
          engageFiltersOnBetweenId,
          engageFiltersStartDate,
          engageFiltersEndDate
        } = separateExtraFilters(selectedFilters);

        const engageDisplayFilters = getFormattedDisplayFilters(
          selectedFilters,
          clone(displayFilters),
          engageFiltersOnBetweenId,
          engageFiltersStartDate,
          engageFiltersEndDate
        );

        return {
          ...state,
          allFilters: displayFilters,
          engageDisplayFilters,
          engageExcludedCustomers,
          engageFiltersEndDate,
          engageFiltersOnBetweenId,
          engageFiltersStartDate,
          engageIncludedCustomers,
          engageSelectedFilters: formattedSelectedFilters,
          engageSelectedSavedGroups: selectedSavedGroups,
          meetDisplayFilters: displayFilters
        };
      } else {
        return state;
      }
    }

    case engage.ActionTypes.FIND_FILTER_DISPLAY_CUSTOMERS_SUCCESS: {
      let { customerProfiles } = action.payload;
      let engageCustomers = clone(state.engageIncludedCustomers);

      // Merge the customer details with the ids here if required
      if (any((customer) => customer.requiresCustomerDetails)(engageCustomers)) {
        engageCustomers.map((engageCustomer, index) => {
          if (engageCustomer.requiresCustomerDetails) {
            const newCustomerData = find(propEq('id', engageCustomer.id))(customerProfiles);
            engageCustomers[index] = newCustomerData ? newCustomerData : engageCustomers[index];
          }
        });
      }

      return { ...state, engageIncludedCustomers: engageCustomers };
    }

    case engage.ActionTypes.GET_CUSTOMERS_BY_ID_SUCCESS: {
      const { customerProfiles } = action.payload;
      let engageIncludedCustomers = state.engageIncludedCustomers;

      // Only replace customer profiles that returned data
      customerProfiles.forEach((profile) => {
        const existingIndex = findIndex(propEq('id', profile.id))(engageIncludedCustomers);
        if (existingIndex > -1) {
          engageIncludedCustomers[existingIndex] = profile;
        }
      });

      return { ...state, engageIncludedCustomers };
    }

    case engage.ActionTypes.GET_EXCLUDED_CUSTOMERS_BY_ID_SUCCESS: {
      const { customerProfiles } = action.payload;

      return { ...state, engageExcludedCustomers: customerProfiles };
    }

    case engage.ActionTypes.GET_SELECTED_FILTER_DETAILS_SUCCESS: {
      let displayFilters = action.payload;
      let engageDisplayFilters = getFormattedDisplayFilters(
        state.engageSelectedFilters,
        displayFilters,
        state.engageFiltersOnBetweenId,
        state.engageFiltersStartDate,
        state.engageFiltersEndDate
      );

      return {
        ...state,
        allFilters: displayFilters,
        engageDisplayFilters,
        meetDisplayFilters: displayFilters
      };
    }

    case engage.ActionTypes.SET_SELECTED_DISPLAY_FILTERS: {
      const {
        selectedFilters,
        selectedSavedGroups,
        includedCustomers,
        excludedCustomers,
        onBetweenId,
        startDate,
        endDate
      } = action.payload;
      let engageDisplayFilters = clone(state.engageDisplayFilters);

      // Update the display filters with any different selected attributes on the applied filters
      engageDisplayFilters = getFormattedDisplayFilters(selectedFilters, engageDisplayFilters, onBetweenId, startDate, endDate);
      engageDisplayFilters.map(filter => filter.open = filter.defaultOpen);

      return {
        ...state,
        engageDisplayFilters,
        engageExcludedCustomers: excludedCustomers,
        engageIncludedCustomers: includedCustomers,
        engageFiltersEndDate: endDate,
        engageFiltersOnBetweenId: onBetweenId,
        engageFiltersStartDate: startDate,
        engageSelectedFilters: selectedFilters,
        engageSelectedSavedGroups: selectedSavedGroups
      };
    }

    case meet.ActionTypes.ADD_CUSTOM_DATE_FILTER:
    case meet.ActionTypes.ADD_SELECTED_FILTER: {
      const page = action.payload.page;
      let addedFilter = action.payload.filter;

      let selectedFilters = page === AppConstants.meetPage ? clone(state.meetSelectedFilters) : clone(state.engageSelectedFilters);
      let filters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let onBetweenFilterId = page === AppConstants.meetPage ? state.meetFiltersOnBetweenId : state.engageFiltersOnBetweenId;
      let filtersStartDate = page === AppConstants.meetPage ? state.meetFiltersStartDate : state.engageFiltersStartDate;
      let filtersEndDate = page === AppConstants.meetPage ? state.meetFiltersEndDate : state.engageFiltersEndDate;

      if (addedFilter.parentCategory !== SharedConstants.filtersDateRangeId) {
        selectedFilters.push(addedFilter);
      } else {
        // The 2 options for the date-range filter are mutually exclusive, so uncheck the one not selected
        const dateFilterIndex = findIndex(propEq('id', SharedConstants.filtersDateRangeId))(filters);
        const onBetweenIndex = findIndex(propEq('id', SharedConstants.filtersOnBetweenId))(filters[dateFilterIndex].subFilters);
        const customIndex = findIndex(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(filters[dateFilterIndex].subFilters);

        if (addedFilter.id === SharedConstants.filtersOnBetweenId) {
          filters[dateFilterIndex].subFilters[onBetweenIndex].isSelected = true;
          filters[dateFilterIndex].subFilters[customIndex].isSelected = false;
          filtersStartDate = null;
          filtersEndDate = null;
        } else if (addedFilter.id === SharedConstants.filtersCustomDateSubFilterId) {
          filters[dateFilterIndex].subFilters[onBetweenIndex].isSelected = false;
          filters[dateFilterIndex].subFilters[customIndex].isSelected = true;

          // Return the date slider's value, title back to their defaults (final element of the stepsArray)
          const defaults = filters[dateFilterIndex].subFilters[onBetweenIndex].sliderOptions.stepsArray.at(-1);
          filters[dateFilterIndex].subFilters[onBetweenIndex].sliderOptions.title = defaults.title;
          filters[dateFilterIndex].subFilters[onBetweenIndex].sliderOptions.value = defaults.value;
          // Set onBetweenFilterId back to default (meet and engage have the same default)
          onBetweenFilterId = initialState.meetFiltersOnBetweenId;
        }
      }

      // Set the default selected button toggle
      if (addedFilter.buttonToggles) {
        let selectedCategory = find(propEq('id', addedFilter.parentCategory))(filters);
        let subFilters = selectedCategory.subFilters;
        let selectedFilter = find(propEq('id', addedFilter.id))(subFilters);
        selectedFilter.buttonToggles.map(toggle => toggle.selected = toggle.defaultToggle);
        selectedCategory.subFilters = subFilters;
        const selectedFilterIndex = findIndex(propEq('id', addedFilter.id))(selectedFilters);
        selectedFilters[selectedFilterIndex] = selectedFilter;
      }

      // Set the default selected button chips
      if (addedFilter.buttonChipSections) {
        addedFilter.buttonChipSections.map(buttonChipSection => {
          let categoryIndex = findIndex(propEq('id', addedFilter.parentCategory))(filters);
          let subFilters = filters[categoryIndex].subFilters;
          let selectedFilter = find(propEq('id', addedFilter.id))(subFilters);
          let chipSectionIndex = findIndex(propEq('id', buttonChipSection.id))(selectedFilter.buttonChipSections);
          let selectedChipSection = selectedFilter.buttonChipSections[chipSectionIndex];

          selectedChipSection.buttonChips.map(chip => chip.selected = chip.defaultChip);
        });
      }

      // Set the default selected slider values
      if (addedFilter.sliderOptions) {
        const selectedCategory = find(propEq('id', addedFilter.parentCategory))(filters);
        const subFilters = selectedCategory.subFilters;
        let selectedFilter = find(propEq('id', addedFilter.id))(subFilters);
        // Check for double slider vs single slider
        if (selectedFilter.sliderOptions.highValue !== null && selectedFilter.sliderOptions.highValue !== undefined) {
          selectedFilter.sliderOptions.value = selectedFilter.sliderOptions.floor;
          selectedFilter.sliderOptions.highValue = selectedFilter.sliderOptions.ceil;
        } else {
          const defaultValue = selectedFilter.sliderOptions.ceil;
          selectedFilter.sliderOptions.value = defaultValue;
          const defaultSliderOption = find(propEq('value', defaultValue))(selectedFilter.sliderOptions.stepsArray);
          selectedFilter.sliderOptions.title = defaultSliderOption.title;
        }
      }

      // Set the default selected subOption values
      if (addedFilter.subOptions) {
        let selectedCategory = find(propEq('id', addedFilter.parentCategory))(filters);
        let subFilters = selectedCategory.subFilters;
        let selectedFilter = find(propEq('id', addedFilter.id))(subFilters);
        selectedFilter.subOptions.map(option => option.selected = false);
        selectedCategory.subFilters = subFilters;
        const selectedFilterIndex = findIndex(propEq('id', addedFilter.id))(selectedFilters);
        selectedFilters[selectedFilterIndex] = selectedFilter;
      }

      // Based on the newly selected filter recheck if there are now any without a selected sub option
      const filtersWithoutSelectedSubOption = updateFiltersWithoutSelectedSubOption(selectedFilters);
      const filtersWithoutSelectedToggle = updateFiltersWithoutSelectedToggle(selectedFilters);

      if (page === AppConstants.meetPage) {
        return {
          ...state,
          meetDisplayFilters: filters,
          meetFiltersEndDate: filtersEndDate,
          meetFiltersOnBetweenId: onBetweenFilterId,
          meetFiltersStartDate: filtersStartDate,
          meetSelectedFilters: selectedFilters,
          page: 0
        };
      } else {
        return {
          ...state,
          engageDisplayFilters: filters,
          engageFiltersEndDate: filtersEndDate,
          engageFiltersOnBetweenId: onBetweenFilterId,
          engageFiltersStartDate: filtersStartDate,
          engageSelectedFilters: selectedFilters,
          filtersWithoutSelectedSubOption,
          filtersWithoutSelectedToggle
        };
      }
    }

    case meet.ActionTypes.ADD_FILTER_FROM_DISCOVER: {
      let addedFilter = action.payload.filter;
      let selectedFilters = [];
      let filters = clone(state.meetDisplayFilters);
      // Currently both membership Discover tile filters are under the Ordering section on Meet
      let parentFilterId = MeetConstants.filtersOrderingCategory;
      let filterId = MeetConstants.filtersSpecifyMembershipID;
      let subOptionId;

      if (addedFilter.id === MeetConstants.isNewCfaOneLastMonth) {
        subOptionId = MeetConstants.newMemberLastMonth;
      } else if (addedFilter.id === MeetConstants.isNewCfaOneThisMonth) {
        subOptionId = MeetConstants.newMemberThisMonth;
      }

      let parentFilter = find(propEq('id', parentFilterId))(filters);
      let selectedFilter = find(propEq('id', filterId))(parentFilter.subFilters);

      if (selectedFilter.subOptions) {
        selectedFilter.subOptions.forEach((subOption) => {
          subOption.selected = subOption.id === subOptionId;
        });
      }

      selectedFilters.push(selectedFilter);

      return {
        ...state,
        meetDisplayFilters: filters,
        meetSelectedFilters: selectedFilters,
        page: 0
      };
    }

    case meet.ActionTypes.ADD_INDIVIDUAL_EXCLUDE_CUSTOMER: {
      const { customer, page } = action.payload;
      let excludedCustomers = page === AppConstants.meetPage ? clone(state.meetExcludedCustomers) : clone(state.engageExcludedCustomers);
      let selectedCustomerIndex = findIndex(propEq('id', customer.id))(excludedCustomers);
      // This is to avoid adding the same customer under Exclude filter when the user removes a customer from saved group customer dialogue
      if (selectedCustomerIndex == -1) {
        excludedCustomers.push(customer);
      }
      if (page === AppConstants.meetPage) {
        return { ...state, meetExcludedCustomers: excludedCustomers };
      } else {
        return { ...state, engageExcludedCustomers: excludedCustomers };
      }
    }

    case meet.ActionTypes.ADD_INDIVIDUAL_INCLUDE_CUSTOMER: {
      const { customer, page } = action.payload;
      let includedCustomers = page === AppConstants.meetPage ? clone(state.meetIncludedCustomers) : clone(state.engageIncludedCustomers);
      includedCustomers.push(customer);

      if (page === AppConstants.meetPage) {
        return { ...state, meetIncludedCustomers: includedCustomers };
      } else {
        return { ...state, engageIncludedCustomers: includedCustomers };
      }
    }

    case meet.ActionTypes.ADD_SAVED_GROUP: {
      const selectedGroup = action.payload.savedGroup;
      const page = action.payload.page;

      let selectedSavedGroups =
        page === AppConstants.meetPage ? clone(state.meetSelectedSavedGroups) : clone(state.engageSelectedSavedGroups);
      selectedSavedGroups.push(selectedGroup);

      if (page === AppConstants.meetPage) {
        return { ...state, meetSelectedSavedGroups: selectedSavedGroups, page: 0 };
      } else {
        return { ...state, engageSelectedSavedGroups: selectedSavedGroups, page: 0 };
      }
    }

    case meet.ActionTypes.CLEAR_FILTERS: {
      let { page } = action.payload;
      let displayFilters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let formattedDisplayFilters = setDateDisplayFilter(displayFilters, SharedConstants.onBetweenDefaultId, null, null);

      if (page === AppConstants.meetPage) {
        return {
          ...state,
          meetDisplayFilters: formattedDisplayFilters,
          meetExcludedCustomers: [],
          meetFiltersEndDate: initialState.meetFiltersEndDate,
          meetFiltersOnBetweenId: initialState.meetFiltersOnBetweenId,
          meetFiltersStartDate: initialState.meetFiltersStartDate,
          meetIncludedCustomers: [],
          meetSelectedFilters: [],
          meetSelectedSavedGroups: [],
          page: 0
        };
      } else {
        return {
          ...state,
          engageDisplayFilters: formattedDisplayFilters,
          engageExcludedCustomers: [],
          engageFiltersEndDate: initialState.engageFiltersEndDate,
          engageFiltersOnBetweenId: initialState.engageFiltersOnBetweenId,
          engageFiltersStartDate: initialState.engageFiltersStartDate,
          engageIncludedCustomers: [],
          engageSelectedFilters: [],
          engageSelectedSavedGroups: []
        };
      }
    }

    case meet.ActionTypes.CREATE_SAVED_GROUP: {
      return { ...state, modifySavedGroupsLoading: true };
    }

    case meet.ActionTypes.CREATE_SAVED_GROUP_ERROR: {
      return { ...state, modifySavedGroupsLoading: false };
    }

    case meet.ActionTypes.CREATE_SAVED_GROUP_SUCCESS: {
      const selectedGroup = action.payload;
      let selectedSavedGroups = [];
      selectedSavedGroups.push(selectedGroup);
      let displayFilters = clone(state.meetDisplayFilters);
      let meetDisplayFilters = setDateDisplayFilter(displayFilters, SharedConstants.onBetweenDefaultId, null, null);

      return {
        ...state,
        meetDisplayFilters,
        meetExcludedCustomers: [],
        meetFiltersEndDate: initialState.meetFiltersEndDate,
        meetFiltersOnBetweenId: initialState.meetFiltersOnBetweenId,
        meetFiltersStartDate: initialState.meetFiltersStartDate,
        meetIncludedCustomers: [],
        meetSelectedFilters: [],
        meetSelectedSavedGroups: selectedSavedGroups,
        modifySavedGroupsLoading: false,
        savedGroupsLoading: true
      }
    }

    case meet.ActionTypes.DELETE_SAVED_GROUP_SUCCESS: {
      return { ...state, savedGroupsLoading: true };
    }

    case meet.ActionTypes.EDIT_SAVED_GROUP: {
      const meetSavedGroupBeingEdited = action.payload;
      const meetSelectedSavedGroups = [meetSavedGroupBeingEdited];
      const selectedFilters = meetSavedGroupBeingEdited.selectedFilters;
      const displayFilters = clone(state.meetDisplayFilters);

      // Remove all filters and individual customers other than those included in the group being edited
      let meetIncludedCustomers = [];
      let meetExcludedCustomers = [];

      meetSelectedSavedGroups.forEach((savedGroup) => {
        if (savedGroup && savedGroup.selectedFilters) {
          savedGroup.selectedFilters.map((selectedFilter) => {
            // Add included customers, excluded customers filters separately
            if (selectedFilter && selectedFilter.id === MeetConstants.includeFilterId) {
              meetIncludedCustomers = selectedFilter.customerProfiles ? selectedFilter.customerProfiles : [];
            } else if (selectedFilter && selectedFilter.id === MeetConstants.excludeFilterId) {
              meetExcludedCustomers = selectedFilter.customerProfiles ? selectedFilter.customerProfiles : [];
            }
          });
        }
      });

      let {
        formattedSelectedFilters,
        engageIncludedCustomers,
        engageExcludedCustomers,
        engageFiltersOnBetweenId,
        engageFiltersStartDate,
        engageFiltersEndDate
      } = separateExtraFilters(selectedFilters);

      let meetFiltersOnBetweenId = engageFiltersOnBetweenId
      let meetFiltersStartDate = engageFiltersStartDate;
      let meetFiltersEndDate = engageFiltersEndDate;

      let formattedDisplayFilters = getFormattedDisplayFilters(
        selectedFilters,
        displayFilters,
        meetFiltersOnBetweenId,
        meetFiltersStartDate,
        meetFiltersEndDate
      );

      return {
        ...state,
        meetDisplayFilters: formattedDisplayFilters,
        meetExcludedCustomers,
        meetFiltersEndDate: meetFiltersEndDate,
        meetFiltersOnBetweenId: meetFiltersOnBetweenId,
        meetFiltersStartDate: meetFiltersStartDate,
        meetIncludedCustomers,
        meetSavedGroupBeingEdited,
        meetSelectedFilters: formattedSelectedFilters,
        meetSelectedSavedGroups: []
      };
    }

    case meet.ActionTypes.EXIT_SAVED_GROUP_EDIT: {
      let meetDisplayFilters = setDateDisplayFilter(clone(state.meetDisplayFilters), SharedConstants.onBetweenDefaultId, null, null);

      return {
        ...state,
        meetDisplayFilters,
        meetExcludedCustomers: [],
        meetFiltersEndDate: initialState.meetFiltersEndDate,
        meetFiltersOnBetweenId: initialState.meetFiltersOnBetweenId,
        meetFiltersStartDate: initialState.meetFiltersStartDate,
        meetIncludedCustomers: [],
        meetSavedGroupBeingEdited: null,
        meetSelectedFilters: [],
        meetSelectedSavedGroups: []
      };
    }

    case meet.ActionTypes.LOAD_FILTERS: {
      // Only set loading if filters are not already on the state
      let filtersLoading = state.allFilters.length === 0;

      return { ...state, filtersLoading };
    }

    case meet.ActionTypes.LOAD_FILTERS_FAILURE: {
      return { ...state, filtersError: action.payload, filtersLoading: false };
    }

    case meet.ActionTypes.LOAD_FILTERS_SUCCESS: {
      let allFilters = action.payload.filters;
      allFilters.map(filter => filter.open = filter.defaultOpen);
      // Default the date range selected to be On/Between
      if (allFilters) {
        const filterIndex = findIndex(propEq('id', SharedConstants.filtersDateRangeId))(allFilters);
        if (filterIndex !== -1) {
          const onBetweenIndex = findIndex(propEq('id', SharedConstants.filtersOnBetweenId))(allFilters[filterIndex].subFilters);
          if (onBetweenIndex !== -1) {
            allFilters[filterIndex].subFilters[onBetweenIndex].isSelected = true;
          }
        }
      }

      return {
        ...state,
        allFilters,
        engageDisplayFilters: allFilters,
        filtersLoading: false,
        meetDisplayFilters: allFilters
      };
    }

    case meet.ActionTypes.LOAD_SAVED_GROUPS_FAILURE: {
      return { ...state, savedGroupsError: action.payload, savedGroupsLoading: false };
    }

    case meet.ActionTypes.LOAD_SAVED_GROUPS_SUCCESS: {
      let savedGroups = action.payload;

      return {
        ...state,
        allSavedGroups: savedGroups,
        engageDisplaySavedGroups: savedGroups,
        meetDisplaySavedGroups: savedGroups,
        savedGroupsLoading: false
      };
    }

    case meet.ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS: {
      return {
        ...state,
        selectedSavedGroupCustomersLoading: true,
        selectedSavedGroupId: action.payload
      };
    }

    case meet.ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_FAILURE: {
      return {
        ...state,
        selectedSavedGroupCustomersError : action.payload,
        selectedSavedGroupCustomersLoading: false
      };
    }

    case meet.ActionTypes.LOAD_SELECTED_SAVED_GROUP_CUSTOMERS_SUCCESS: {
      let { customerProfiles, largeGroup } = action.payload.responseObject;
      return {
        ...state,
        isSelectedSavedGroupLarge: largeGroup,
        selectedSavedGroupCustomers: customerProfiles ? customerProfiles : [],
        selectedSavedGroupCustomersError: null,
        selectedSavedGroupCustomersLoading: false
      };
    }

    case meet.ActionTypes.REMOVE_INDIVIDUAL_EXCLUDE_CUSTOMER: {
      let customerToRemove = action.payload.customer;
      let page = action.payload.page;
      let excludedCustomers = page === AppConstants.meetPage ? clone(state.meetExcludedCustomers) : clone(state.engageExcludedCustomers);
      let selectedCustomerIndex = findIndex(propEq('id', customerToRemove.id))(excludedCustomers);
      excludedCustomers.splice(selectedCustomerIndex, 1);

      if (page === AppConstants.meetPage) {
        return { ...state, meetExcludedCustomers: excludedCustomers };
      } else {
        return { ...state, engageExcludedCustomers: excludedCustomers };
      }
    }

    case meet.ActionTypes.REMOVE_INDIVIDUAL_INCLUDE_CUSTOMER: {
      let customerToRemove = action.payload.customer;
      let page = action.payload.page;
      let includedCustomers = page === AppConstants.meetPage ? clone(state.meetIncludedCustomers) : clone(state.engageIncludedCustomers);
      let selectedCustomerIndex = findIndex(propEq('id', customerToRemove.id))(includedCustomers);
      includedCustomers.splice(selectedCustomerIndex, 1);

      if (page === AppConstants.meetPage) {
        return { ...state, meetIncludedCustomers: includedCustomers };
      } else {
        return { ...state, engageIncludedCustomers: includedCustomers };
      }
    }

    case meet.ActionTypes.REMOVE_SAVED_GROUP: {
      const selectedGroup = action.payload.savedGroup;
      const page = action.payload.page;
      let meetSavedGroupBeingEdited = clone(state.meetSavedGroupBeingEdited);
      let selectedSavedGroups =
        page === AppConstants.meetPage ? clone(state.meetSelectedSavedGroups) : clone(state.engageSelectedSavedGroups);

      let selectedGroupIndex = findIndex(propEq('id', selectedGroup.id))(selectedSavedGroups);
      selectedSavedGroups.splice(selectedGroupIndex, 1);

      if (page === AppConstants.meetPage) {
        return { ...state, meetSavedGroupBeingEdited, meetSelectedSavedGroups: selectedSavedGroups, page: 0 };
      } else {
        return { ...state, engageSelectedSavedGroups: selectedSavedGroups };
      }
    }

    case meet.ActionTypes.REMOVE_SELECTED_FILTER: {
      const filterToRemove = action.payload.filter;
      const page = action.payload.page;

      let filters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let selectedFilters = page === AppConstants.meetPage ? clone(state.meetSelectedFilters) : clone(state.engageSelectedFilters);
      let selectedFilterIndex = findIndex(propEq('id', filterToRemove.id))(selectedFilters);

      if (filterToRemove.parentCategory == SharedConstants.filtersDateRangeId) {
        // The 2 options for the date-range filter are mutually exclusive and one must be on, so check the one that wasn't just deselected
        const dateFilterIndex = findIndex(propEq('id', SharedConstants.filtersDateRangeId))(filters);
        const onBetweenIndex = findIndex(propEq('id', SharedConstants.filtersOnBetweenId))(filters[dateFilterIndex].subFilters);
        const customIndex = findIndex(propEq('id', SharedConstants.filtersCustomDateSubFilterId))(filters[dateFilterIndex].subFilters);

        if (filterToRemove.id === SharedConstants.filtersOnBetweenId) {
          filters[dateFilterIndex].subFilters[onBetweenIndex].isSelected = false;
          filters[dateFilterIndex].subFilters[customIndex].isSelected = true;
        } else if (filterToRemove.id === SharedConstants.filtersCustomDateSubFilterId) {
          filters[dateFilterIndex].subFilters[onBetweenIndex].isSelected = true;
          filters[dateFilterIndex].subFilters[customIndex].isSelected = false;
        }
      } else {
        selectedFilters.splice(selectedFilterIndex, 1);
      }

      // Based on the newly removed filter recheck if there are now any without a selected sub option
      const filtersWithoutSelectedSubOption = updateFiltersWithoutSelectedSubOption(selectedFilters);
      const filtersWithoutSelectedToggle = updateFiltersWithoutSelectedToggle(selectedFilters);

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: filters, meetSelectedFilters: selectedFilters, page: 0 };
      } else {
        return {
          ...state,
          engageDisplayFilters: filters,
          engageSelectedFilters: selectedFilters,
          filtersWithoutSelectedSubOption,
          filtersWithoutSelectedToggle
        };
      }
    }

    case meet.ActionTypes.REMOVE_SELECTED_SAVED_GROUP_CUSTOMER: {
      let customerToRemove = action.payload;
      let selectedCustomerIndex = findIndex(propEq('id', customerToRemove.id))(state.selectedSavedGroupCustomers);
      let selectedSavedGroupCustomers = clone(state.selectedSavedGroupCustomers);
      selectedSavedGroupCustomers.splice(selectedCustomerIndex, 1);
      return {
        ...state,
        selectedSavedGroupCustomers: selectedSavedGroupCustomers
      }
    }

    case meet.ActionTypes.TOGGLE_CATEGORY_EXPANSION: {
      let { category, page } = action.payload;

      let filters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let filterCategoryIndex = findIndex(propEq('id', category.id))(filters);
      filters.map(currentFilter => {
        currentFilter.open = (filters.indexOf(currentFilter) === filterCategoryIndex) ? !currentFilter.open : false
      });

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: filters };
      } else {
        return { ...state, engageDisplayFilters: filters };
      }
    }

    case meet.ActionTypes.UPDATE_BUTTON_CHIP_ATTRIBUTE: {
      let { chip, filter, chipSection, page } = action.payload;
      let areAnyChipsSelected = false;
      let selectedFilters = page === AppConstants.meetPage ? clone(state.meetSelectedFilters) : clone(state.engageSelectedFilters);
      let parentCategories = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let selectedCategory = find(propEq('id', filter.parentCategory))(parentCategories);

      let filters = selectedCategory.subFilters;
      let selectedFilter = find(propEq('id', filter.id))(filters);
      let selectedChipSection = find(propEq('id', chipSection.id))(selectedFilter.buttonChipSections);

      // Select the chip for the correct section and deselect it from any other sections
      selectedFilter.buttonChipSections.map(buttonChipSection => {
        let selectedChip = find(propEq('id', chip.id))(buttonChipSection.buttonChips);
        let selectedChipIndex = findIndex(propEq('id', chip.id))(buttonChipSection.buttonChips);

        if (selectedChipIndex > -1) {
          if (selectedChipSection.id === buttonChipSection.id) {
            // Set chip states for the section that the selected chip is in
            selectedChip.selected = !selectedChip.selected;
          } else {
            // Set chip states for the section that the selected chip is not in
            selectedChip.selected = false;
          }

          // Check if any chips in this section are selected
          if (any(propEq('selected', true))(buttonChipSection.buttonChips)) {
            areAnyChipsSelected = true;
          }
        }
      });

      // If no chips are selected on any of the button chip sections, remove the filter
      if (!areAnyChipsSelected) {
        let selectedFilterIndex = findIndex(propEq('id', filter.id))(selectedFilters);
        selectedFilters.splice(selectedFilterIndex, 1);
      }

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: parentCategories, meetSelectedFilters: selectedFilters };
      } else {
        return { ...state, engageDisplayFilters: parentCategories, engageSelectedFilters: selectedFilters };
      }
    }

    case meet.ActionTypes.UPDATE_BUTTON_TOGGLE_ATTRIBUTE: {
      let { categoryId, filter, valueId, page } = action.payload;
      let parentCategories = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let selectedFilters = page === AppConstants.meetPage ? clone(state.meetSelectedFilters) : clone(state.engageSelectedFilters);
      let selectedCategory = find(propEq('id', categoryId))(parentCategories);

      let filters = selectedCategory.subFilters;
      let selectedFilter = find(propEq('id', filter.id))(filters);

      selectedFilter.buttonToggles.map(toggle => {
        toggle.selected = toggle.id === valueId ? !toggle.selected : false;
      });

      const selectedFilterIndex = findIndex(propEq('id', selectedFilter.id))(selectedFilters);
      selectedFilters[selectedFilterIndex] = selectedFilter;

      // Based on the newly removed filter recheck if there are now any without a selected toggle
      const filtersWithoutSelectedToggle = updateFiltersWithoutSelectedToggle(selectedFilters);

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: parentCategories, meetSelectedFilters: selectedFilters };
      } else {
        return { ...state, engageDisplayFilters: parentCategories, engageSelectedFilters: selectedFilters, filtersWithoutSelectedToggle };
      }
    }

    case meet.ActionTypes.UPDATE_FILTER_DATE_RANGE_OPTION:
    case meet.ActionTypes.UPDATE_FILTER_DATES: {
      const { dates, page } = action.payload;
      let endDate = null;
      let stateStartDate = page === AppConstants.meetPage ? state.meetFiltersStartDate : state.engageFiltersStartDate;
      let stateEndDate = page === AppConstants.meetPage ? state.meetFiltersEndDate : state.engageFiltersEndDate;

      if (dates && dates.endDate) {
        endDate = moment(dates.endDate).isBefore(moment(dates.startDate)) ? dates.startDate : dates.endDate;
      }

      let formattedStartDate = dates && dates.startDate ? moment(dates.startDate).format('YYYY-MM-DD') : stateStartDate;
      let formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : stateEndDate;
      let displayFilters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);

      // If sliderValue is 1000 that means the "Any Days" option is selected and both start and end date should be set to null
      if (!dates || (dates && dates.sliderValue === SharedConstants.onBetweenDefaultValue)) {
        formattedStartDate = null;
        formattedEndDate = null;
      }

      if (page === AppConstants.meetPage) {
        return {
          ...state,
          meetDisplayFilters: displayFilters,
          meetFiltersEndDate: formattedEndDate,
          meetFiltersOnBetweenId: initialState.meetFiltersOnBetweenId,
          meetFiltersStartDate: formattedStartDate
        };
      } else {
        return {
          ...state,
          engageDisplayFilters: displayFilters,
          engageFiltersEndDate: formattedEndDate,
          engageFiltersOnBetweenId: initialState.engageFiltersOnBetweenId,
          engageFiltersStartDate: formattedStartDate
        };
      }
    }

    case meet.ActionTypes.UPDATE_SAVED_GROUP: {
      return { ...state, modifySavedGroupsLoading: true };
    }

    case meet.ActionTypes.UPDATE_SAVED_GROUP_ERROR: {
      return {
        ...state,
        meetExcludedCustomers: [],
        meetIncludedCustomers: [],
        meetSavedGroupBeingEdited: null,
        meetSelectedFilters: [],
        meetSelectedSavedGroups: [],
        modifySavedGroupsLoading: false
      };
    }

    case meet.ActionTypes.UPDATE_SAVED_GROUP_SUCCESS: {
      const savedGroup = action.payload;
      const meetSelectedSavedGroups = [savedGroup];

      return {
        ...state,
        meetExcludedCustomers: [],
        meetIncludedCustomers: [],
        meetSavedGroupBeingEdited: null,
        meetSelectedFilters: [],
        meetSelectedSavedGroups,
        modifySavedGroupsLoading: false,
        savedGroupsLoading: true
      };
    }

    case meet.ActionTypes.UPDATE_SLIDER_VALUE: {
      const { value, id, parentId, page } = action.payload;
      let filtersSelectedOnBetweenId = page === AppConstants.meetPage ? state.meetFiltersOnBetweenId : state.engageFiltersOnBetweenId;

      let displayFilters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      const filter = find(propEq('id', parentId))(displayFilters);
      let subFilter = find(propEq('id', id))(filter.subFilters);
      const selectedOption = find(propEq('value', value))(subFilter.sliderOptions.stepsArray);

      subFilter.sliderOptions.title = selectedOption && selectedOption.title ? selectedOption.title : '';
      subFilter.sliderOptions.value = value;

      // Update the on/between variable if it is the date slider that has been updated
      if (id === SharedConstants.filtersOnBetweenId) {
        filtersSelectedOnBetweenId = selectedOption.id;
      }

      if (page === AppConstants.meetPage) {
        return {
          ...state,
          meetDisplayFilters: displayFilters,
          meetFiltersOnBetweenId: filtersSelectedOnBetweenId,
          page: 0
        };
      } else {
        return {
          ...state,
          engageDisplayFilters: displayFilters,
          engageFiltersOnBetweenId: filtersSelectedOnBetweenId,
          page: 0
        };
      }
    }

    case meet.ActionTypes.UPDATE_SUB_OPTION_ATTRIBUTE: {
      let { filter, page, subOption } = action.payload;
      let selectedFilters = page === AppConstants.meetPage ? clone(state.meetSelectedFilters) : clone(state.engageSelectedFilters);
      let parentCategories = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      let selectedCategory = find(propEq('id', filter.parentCategory))(parentCategories);

      let displayFilters = selectedCategory.subFilters;
      let selectedDisplayFilter = find(propEq('id', filter.id))(displayFilters);
      let displaySubOption = find(propEq('id', subOption.id))(selectedDisplayFilter.subOptions);
      displaySubOption.selected = !displaySubOption.selected;

      let selectedFilter = find(propEq('id', filter.id))(selectedFilters);
      let selectedSubOption = find(propEq('id', subOption.id))(selectedFilter.subOptions);
      selectedSubOption.selected = !selectedSubOption.selected;

      // Based on the newly removed filter recheck if there are now any without a selected sub option
      const filtersWithoutSelectedSubOption = updateFiltersWithoutSelectedSubOption(selectedFilters);

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: parentCategories, meetSelectedFilters: selectedFilters };
      } else {
        return {
          ...state,
          engageDisplayFilters: parentCategories,
          engageSelectedFilters: selectedFilters,
          filtersWithoutSelectedSubOption
        };
      }
    }

    case meet.ActionTypes.UPDATE_TWO_VARIABLE_SLIDER_VALUE: {
      const { value, highValue, id, parentId, page } = action.payload;
      let displayFilters = page === AppConstants.meetPage ? clone(state.meetDisplayFilters) : clone(state.engageDisplayFilters);
      const displayFilterIndex = findIndex(propEq('id', parentId))(displayFilters);

      if (value !== null && highValue !== null && id && displayFilterIndex !== -1) {
        const displaySubFilterIndex = findIndex(propEq('id', id))(displayFilters[displayFilterIndex].subFilters);
        displayFilters[displayFilterIndex].subFilters[displaySubFilterIndex].sliderOptions.value = value;
        displayFilters[displayFilterIndex].subFilters[displaySubFilterIndex].sliderOptions.highValue = highValue;
      }

      if (page === AppConstants.meetPage) {
        return { ...state, meetDisplayFilters: displayFilters, page: 0 };
      } else {
        return { ...state, engageDisplayFilters: displayFilters, page: 0 };
      }
    }

    case user.ActionTypes.GET_LOCATION_DETAILS_SUCCESS: {
      return { ...state, page: 0, searchTerm: '' };
    }

    case user.ActionTypes.SELECT_LOCATION: {
      return {
        ...state,
        allFilters: [],
        allSavedGroups: [],
        meetDisplayFilters: [],
        meetDisplaySavedGroups: [],
        meetExcludedCustomers: [],
        meetFiltersEndDate: initialState.meetFiltersEndDate,
        meetFiltersOnBetweenId: initialState.meetFiltersOnBetweenId,
        meetFiltersStartDate: initialState.meetFiltersStartDate,
        meetIncludedCustomers: [],
        meetSelectedFilters: [],
        meetSelectedSavedGroups: [],
        savedGroupsError: null,
        savedGroupsLoading: true
      };
    }

    default: {
      return state;
    }
  }
}

function updateViewState(view) {
  switch (view) {
    case MeetConstants.cateringView: {
      return {
        columns: MeetConstants.cateringCustomersTableColumns,
        inactiveFlag: Inactives.EXCLUDE_INACTIVES,
        isCatering: true,
        isFeedback: false,
        isKidsClub: false
      };
    }

    case MeetConstants.feedbackView: {
      return {
        columns: MeetConstants.feedbackTableColumns,
        inactiveFlag: Inactives.EXCLUDE_INACTIVES,
        isCatering: false,
        isFeedback: true,
        isKidsClub: false
      };
    }

    case MeetConstants.inactivesView: {
      return {
        columns: MeetConstants.inactivesTableColumns,
        inactiveFlag: Inactives.ONLY_INACTIVES,
        isCatering: false,
        isFeedback: false,
        isKidsClub: false
      };
    }

    default: {
      return {
        columns: MeetConstants.allCustomersTableColumns,
        inactiveFlag: Inactives.EXCLUDE_INACTIVES,
        isCatering: false,
        isFeedback: false,
        isKidsClub: false
      };
    }
  }
}

export const getAllFilters = (state: State) => state.allFilters;

export const getAllSavedGroups = (state: State) => state.allSavedGroups;

export const getAutoCompleteSelectedCustomer = (state: State) => state.selectedCustomer;

export const getColumns = (state: State) => state.columns;

export const getColumnsWithFlag = (state: State) => state.columnsWithFlag;

export const getCount = (state: State) => state.count;

export const getCustomers = (state: State) => state.customers;

export const getDisplayErrorText = (state: State) => state.displayErrorText;

export const getEngageDisplayFilters = (state: State) => state.engageDisplayFilters;

export const getEngageDisplaySavedGroups = (state: State) => state.engageDisplaySavedGroups;

export const getEngageExcludedCustomers = (state: State) => state.engageExcludedCustomers;

export const getEngageFiltersEndDate = (state: State) => state.engageFiltersEndDate;

export const getEngageFiltersOnBetweenId = (state: State) => state.engageFiltersOnBetweenId;

export const getEngageFiltersStartDate = (state: State) => state.engageFiltersStartDate;

export const getEngageIncludedCustomers = (state: State) => state.engageIncludedCustomers;

export const getEngageSelectedFilters = (state: State) => state.engageSelectedFilters;

export const getEngageSelectedSavedGroups = (state: State) => state.engageSelectedSavedGroups;

export const getError = (state: State) => state.error;

export const getFiltersError = (state: State) => state.filtersError;

export const getFiltersLoading = (state: State) => state.filtersLoading;

export const getFiltersWithoutSelectedSubOption = (state: State) => state.filtersWithoutSelectedSubOption;

export const getFiltersWithoutSelectedToggle = (state: State) => state.filtersWithoutSelectedToggle;

export const getLoading = (state: State) => state.autocompleteLoading;

export const getMeetDisplayFilters = (state: State) => state.meetDisplayFilters;

export const getMeetDisplaySavedGroups = (state: State) => state.meetDisplaySavedGroups;

export const getMeetExcludedCustomers = (state: State) => state.meetExcludedCustomers;

export const getMeetFiltersEndDate = (state: State) => state.meetFiltersEndDate;

export const getMeetFiltersOnBetweenId = (state: State) => state.meetFiltersOnBetweenId;

export const getMeetFiltersStartDate = (state: State) => state.meetFiltersStartDate;

export const getMeetIncludedCustomers = (state: State) => state.meetIncludedCustomers;

export const getMeetSavedGroupBeingEdited = (state: State) => state.meetSavedGroupBeingEdited;

export const getMeetSelectedFilters = (state: State) => state.meetSelectedFilters;

export const getMeetSelectedSavedGroups = (state: State) => state.meetSelectedSavedGroups;

export const getModifySavedGroupsLoading = (state: State) => state.modifySavedGroupsLoading;

export const getPage = (state: State) => state.page;

export const getPageLimit = (state: State) => state.limit;

export const getSavedGroupsError = (state: State) => state.savedGroupsError;

export const getSavedGroupsLoading = (state: State) => state.savedGroupsLoading;

export const getSearchBy = (state: State) => state.searchBy

export const getSearchTerm = (state: State) => state.searchTerm;

export const getSelectedSavedGroupCustomers = (state: State) => state.selectedSavedGroupCustomers;

export const getSelectedSavedGroupCustomersError = (state: State) => state.selectedSavedGroupCustomersError;

export const getSelectedSavedGroupCustomersLoading = (state: State) => state.selectedSavedGroupCustomersLoading;

export const getSelectedSavedGroupId = (state: State) => state.selectedSavedGroupId;

export const getSort = (state: State) => state.sort;

export const getSortOrder = (state: State) => state.sortOrder;

export const getView = (state: State) => state.view;

export const isSelectedSavedGroupLarge = (state: State) => state.isSelectedSavedGroupLarge;
